import React from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ColumnLabel({ field, label }) {
    const searchParams = new URLSearchParams(window.location.search);

    // get list of ordering filters
    let ordering = searchParams.get('ord') || '';

    // get direction
    let dir = searchParams.get('dir') || '';
    let fieldIsOrdered = ordering === field;

    // currentFilter
    const history = useHistory();
    const arrow = dir === 'ASC' ? 'arrow-up' : 'arrow-down';

    const cleanOrder = (history) => {
        searchParams.delete('ord');
        searchParams.delete('dir');
        history.push(`?${searchParams.toString()}`);
    };

    const setOrder = (history, field) => {
        if (fieldIsOrdered) {
            let newDir = dir === 'ASC' ? 'DESC' : 'ASC';
            searchParams.set('dir', newDir);
        } else {
            searchParams.set('ord', field);
            searchParams.set('dir', 'ASC');
        }
        history.push(`?${searchParams.toString()}`);
    };

    if (!fieldIsOrdered) {
        return (
            <div className="text d-inline-flex">
                <a onClick={() => setOrder(history, field)} className="kt-font-bold text-primary">
                    {label}
                </a>
            </div>
        );
    }

    return (
        <div className="text d-inline-flex">
            <div className="sortoptions mr-1">
                <a
                    className="sortremove text-danger mr-2"
                    onClick={() => cleanOrder(history)}
                    title="Remover do Filtro"
                >
                    <FontAwesomeIcon className="danger" icon={'times'} />
                </a>
                <a
                    onClick={() => setOrder(history, field)}
                    className="text-primary"
                    title="Ativar / Desativar Ordenação"
                >
                    <FontAwesomeIcon className="text-dark" icon={arrow} />
                </a>
            </div>
            <a className="kt-font-bold text-primary" onClick={() => setOrder(history, field)}>
                {label}
            </a>
        </div>
    );
}

export default function DatatableColumn({ label, field, isOrderable, size }) {
    let colSize = size ? ' datatable-col-' + size : '';
    return (
        <th scope="col" className={'column-' + field + colSize}>
            <div className="text d-inline-flex">
                {isOrderable && <ColumnLabel label={label} field={field} />}
                {!isOrderable && label}
            </div>
            <div className="clear" />
        </th>
    );
}
