import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Boolean({ value }) {
    if (value) {
        return (
            <span className="badge badge-success text-white">
                <FontAwesomeIcon icon={'check'} className={'mr-2'} />
                Sim
            </span>
        );
    }
    return (
        <span className="badge badge-danger">
            <FontAwesomeIcon icon={'times'} className={'mr-2'} />
            Não
        </span>
    );
}
