export function addOrRemove(array, item) {
    const newArray = array;
    const exists = newArray.includes(item);

    if (exists) {
        let index = newArray.indexOf(item);
        newArray.splice(index, 1);
        return newArray;
    }

    newArray.push(item);
    return newArray;
}

export function isEmpty(obj) {
    for (let i in obj) {
        return false;
    }
    return true;
}
