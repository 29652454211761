import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BreadcrumbContext } from 'components/Breadcrumbs/BreadcrumbContext';
import { DatatableContext, DatatableContextProvider } from 'components/DataTables/DatatableContext';
import TableLink from 'components/DataTables/TableLink';
import FieldWrapper from 'components/Form/Fields/FieldWrapper';
import ReadOnlyField from 'components/Form/Fields/ReadOnlyField';
import Select from 'components/Form/Fields/Select';
import Panel from 'components/Form/Panel';
import ProgressList from 'components/Utils/Dashboard/ProgressList';
import Badge from 'components/Utils/DataTable/Badge';
import { status } from 'helpers/constants';
import { money } from 'helpers/format';
import AdminDatatable from 'layouts/admin/AdminDatatable';
import { reverse } from 'named-urls/src';
import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { urls } from 'routes/app';
import ClientInvoice from 'services/admin/invoice';

export default function InvoiceList() {
    const title = 'Lista de Faturas';
    let { month, year } = useParams();
    const { setTitle, setBreadcrumbs } = useContext(BreadcrumbContext);
    const history = useHistory();
    const add_url = reverse(urls.admin.invoice.add, { month: month, year: year });

    let [selectedMonth, setMonth] = useState(month);
    let [selectedYear, setYear] = useState(year);

    const months = [
        { label: 'Janeiro', value: 1 },
        { label: 'Fevereiro', value: 2 },
        { label: 'Março', value: 3 },
        { label: 'Abril', value: 4 },
        { label: 'Maio', value: 5 },
        { label: 'Junho', value: 6 },
        { label: 'Julho', value: 7 },
        { label: 'Agosto', value: 8 },
        { label: 'Setembro', value: 9 },
        { label: 'Outubro', value: 10 },
        { label: 'Novembro', value: 11 },
        { label: 'Dezembro', value: 12 }
    ];

    const years = [
        { label: '2020', value: 2020 },
        { label: '2021', value: 2021 },
        { label: '2022', value: 2022 },
        { label: '2023', value: 2023 },
        { label: '2024', value: 2024 },
        { label: '2025', value: 2025 },
        { label: '2026', value: 2026 }
    ];

    const breadcrumbs = [
        {
            label: title,
            url: ''
        }
    ];

    const nextMonth = () => {
        let newYear = parseFloat(year);
        let newMonth = parseFloat(month) + 1;
        if (newMonth > 12) {
            newMonth = 1;
            newYear = newYear + 1;
        }
        updateDate(newMonth, newYear);
    };

    const previousMonth = () => {
        let newYear = parseFloat(year);
        let newMonth = parseFloat(month) - 1;
        if (newMonth < 1) {
            newMonth = 12;
            newYear = newYear - 1;
        }
        updateDate(newMonth, newYear);
    };

    const updateDate = (month, year) => {
        setMonth(month);
        setYear(year);
        let url = reverse(urls.admin.invoice.list, {
            month: month,
            year: year
        });
        history.push({ pathname: url, search: location.search });
    };

    const columns = [
        {
            size: 'id',
            field: 'id',
            label: 'ID',
            render: (row) => (
                <TableLink
                    to={reverse(urls.admin.invoice.edit, {
                        month: month,
                        year: year,
                        id_invoice: row.id
                    })}
                >
                    {String(row.id).padStart(6, '0')}
                </TableLink>
            )
        },
        {
            field: 'client.name',
            label: 'Cliente',
            ordering: true,
            render: (row) => (
                <span>
                    {row.client.name} - {row.client.company_name}
                </span>
            )
        },
        {
            field: 'submission_date',
            label: 'Data para Envio'
        },
        {
            field: 'expiration_date',
            label: 'Limite Pagamento'
        },
        {
            field: 'total',
            label: 'Valor Total',
            render: (row) => {
                return money(row['total']);
            }
        },
        {
            field: 'link',
            label: 'Link',
            render: (row) => {
                return (
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href={reverse(urls.client.invoice, { uuid: row.uuid })}
                    >
                        Acessar
                        {row.expired && (
                            <span className="ml-2 badge-danger badge">
                                <FontAwesomeIcon icon="exclamation-triangle" />
                            </span>
                        )}
                    </a>
                );
            }
        },
        {
            field: 'id_status',
            label: 'Status',
            render: (row) => {
                return <Badge {...status[row.id_status]} />;
            }
        }
    ];

    useEffect(() => {
        setTitle(title);
        setBreadcrumbs(breadcrumbs);
    }, []);

    return (
        <DatatableContextProvider
            source={new ClientInvoice(0, month, year)}
            columns={columns}
            topButtons={[{ label: 'Adicionar', url: add_url, icon: 'plus' }]}
            addUrl={add_url}
        >
            <div className="d-flex flex-row">
                <div style={{ maxWidth: '25rem', minWidth: '24rem' }} className="mr-3">
                    <Panel title={'Dados'}>
                        <ReadOnlyField label={'Ações'}>
                            <div>
                                <Button
                                    onClick={previousMonth}
                                    className={'btn-sm btn-default mr-2'}
                                    type={'button'}
                                >
                                    <FontAwesomeIcon icon={'caret-left'} className={'mr-2'} />
                                    Anterior
                                </Button>
                                <Button
                                    onClick={nextMonth}
                                    className={'btn-sm btn-default'}
                                    type={'button'}
                                >
                                    Próximo
                                    <FontAwesomeIcon icon={'caret-right'} className={'ml-2'} />
                                </Button>
                            </div>
                        </ReadOnlyField>
                        <FieldWrapper label="Mês">
                            <Select
                                name="month"
                                value={selectedMonth}
                                onChange={(e) => {
                                    updateDate(e.target.value, selectedYear);
                                }}
                                options={months}
                            />
                        </FieldWrapper>
                        <FieldWrapper label="Ano">
                            <Select
                                name="year"
                                value={selectedYear}
                                onChange={(e) => {
                                    updateDate(selectedMonth, e.target.value);
                                }}
                                label={'Ano'}
                                options={years}
                            />
                        </FieldWrapper>
                    </Panel>
                    <Panel title={'Informações'}>
                        <ListProgress status={status} />
                    </Panel>
                </div>
                <div className="flex-grow-1">
                    <AdminDatatable />
                </div>
            </div>
        </DatatableContextProvider>
    );
}

function ListProgress({ status }) {
    const { responseData } = useContext(DatatableContext);
    const { info } = responseData;
    let data = [];

    if (!info) {
        return <></>;
    }

    let total = 0;
    for (let item of info) {
        total = total + parseFloat(item.total);
        data.push({
            label: status[parseInt(item.id_status)].label,
            color: status[parseInt(item.id_status)].color,
            value: item.total
        });
    }

    return <ProgressList total={total} data={data} />;
}
