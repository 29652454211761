import React from 'react';

function HeaderBottom({ children }) {
    return (
        <div className="kt-header__bottom">
            <div className="kt-container kt-container--fluid">
                <button
                    className="kt-header-menu-wrapper-close"
                    id="kt_header_menu_mobile_close_btn"
                >
                    <i className="la la-close" />
                </button>

                <div className="kt-header-menu-wrapper" id="kt_header_menu_wrapper">
                    <div id="kt_header_menu" className="kt-header-menu kt-header-menu-mobile ">
                        <ul className="kt-menu__nav ">{children}</ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HeaderBottom;
