import { include } from 'named-urls';

export const urls = {
    auth: include('/auth', {
        login: 'login'
    }),
    client: include('/client', {
        invoice: 'invoice/:uuid/'
    }),
    admin: include('/admin/', {
        index: '',
        change_password: 'change-password',
        client: include('client', {
            list: '',
            edit: ':id_client(\\d+)/edit',
            database: 'database/:id_client(\\d+)/edit',
            add: 'add',
            delete: ':id_client(\\d+)/delete',
            invoice: include(':id_client(\\d+)/invoice', {
                list: ''
            })
        }),
        invoice_all: include('invoice', {
            list: ''
        }),
        invoice: include('invoice/m/:month(\\d+)/y/:year(\\d+)', {
            list: '',
            edit: ':id_invoice(\\d+)/edit',
            add: 'add',
            delete: ':id_invoice(\\d+)/delete'
        }),
        service: include('service', {
            list: '',
            edit: ':id_service(\\d+)/edit',
            add: 'add',
            delete: ':id_service(\\d+)/delete'
        }),
        user: include('user', {
            list: '',
            edit: ':id_user(\\d+)/edit'
        }),
        settings: include('settings', {
            list: '',
            edit: ':id_setting(\\d+)/edit',
            generate_invoices: 'generate-invoices'
        }),
        database: include('database', {
            list: '',
            edit: ':id_database(\\d+)/edit',
            add: 'add',
            delete: ':id_database(\\d+)/delete'
        })
    })
};
