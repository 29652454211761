import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Switch from 'components/Form/Fields/Switch';
import { ErrorMessage, FieldArray, useFormikContext } from 'formik';
import React from 'react';

export default function MultiSelectField({ label, name, choices }) {
    const { values } = useFormikContext();

    const ErrorField = ({ children }) => (
        <div className="alert alert-elevate alert-light" role="alert">
            <div className="alert-icon">
                <FontAwesomeIcon
                    style={{ fontSize: '2.4rem' }}
                    icon={'times'}
                    className={'text-danger'}
                />
            </div>
            <div className="text-dark kt-font-bold mt-2">{children}</div>
        </div>
    );

    return (
        <>
            <ErrorMessage name={name} component={ErrorField} />
            <table className="table-striped table">
                <thead className="bg-primary text-white">
                    <tr>
                        <th>#</th>
                        <th>{label}</th>
                    </tr>
                </thead>
                <tbody className="kt-font-medium">
                    <FieldArray
                        name={name}
                        render={(arrayHelpers) => {
                            return (
                                <>
                                    {choices.map((data, i) => {
                                        return (
                                            <tr key={i}>
                                                <td
                                                    style={{ width: '77px' }}
                                                    className={'multiselect-table'}
                                                >
                                                    <Switch
                                                        key={i}
                                                        size={'sm'}
                                                        checked={
                                                            values[name] &&
                                                            values[name].includes(data.value)
                                                        }
                                                        onChange={(e) => {
                                                            if (e.target.checked) {
                                                                arrayHelpers.push(data.value);
                                                            } else {
                                                                const idx = values[name].indexOf(
                                                                    data.value
                                                                );
                                                                arrayHelpers.remove(idx);
                                                            }
                                                        }}
                                                        name={name + '.' + i}
                                                    />
                                                </td>
                                                <td className="align-middle">
                                                    <span className={'kt-font-medium'}>
                                                        <span htmlFor={'id_' + name + '.' + i}>
                                                            {data.label}
                                                        </span>
                                                    </span>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </>
                            );
                        }}
                    />
                </tbody>
            </table>
        </>
    );
}
