import FieldWrapper from 'components/Form/Fields/FieldWrapper';
import Panel from 'components/Form/Panel';
import LoadingSpinner from 'components/Utils/Spinner/LoadingSpinner';
import { useFormikContext } from 'formik';
import { isEmpty } from 'helpers/array';
import AdminFormLayout from 'layouts/admin/AdminFormLayout';
import { reverse } from 'named-urls/src';
import React, { useEffect, useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { urls } from 'routes/app';
import ClientDatabase from 'services/admin/client_database';

export default function FormClientDatabase() {
    const { id_client } = useParams();

    const title = 'Edição de Bases';
    const formUrls = {
        edit: reverse(urls.admin.client.database.edit, { id_client: id_client })
    };

    const breadcrumbs = [
        {
            label: 'Cliente #' + id_client,
            url: reverse(urls.admin.client.edit, { id_client: id_client })
        },
        {
            label: title,
            url: ''
        }
    ];

    return (
        <AdminFormLayout
            id={id_client}
            source={new ClientDatabase(id_client)}
            initialValues={{}}
            breadcrumbs={breadcrumbs}
            title={title}
            urls={formUrls}
        >
            <Form />
        </AdminFormLayout>
    );
}

function Form({ options }) {
    const { values, setFieldValue } = useFormikContext();
    const [databases, setDatabases] = useState([]);
    const [buscando, setBuscando] = useState(true);

    let selectedDatabases = values.databases;
    let selectedIds = [];
    for (let item of selectedDatabases) {
        selectedIds.push(item.id);
    }

    if (isEmpty(values)) {
        return <LoadingSpinner />;
    }

    const searchDatabases = (value) => {
        setBuscando(true);
        new ClientDatabase()
            .options({ q: value })
            .then(({ data }) => {
                console.log(data);
                setDatabases(data);
            })
            .finally(() => {
                setBuscando(false);
            });
    };

    useEffect(() => {
        searchDatabases('');
    }, []);

    const addDatabase = (item) => {
        let newDatabases = values.databases || [];
        newDatabases.push(item);
        setFieldValue('databases', newDatabases);
    };

    const removeDatabase = (item) => {
        let index = selectedIds.indexOf(item.id);
        let newDatabases = selectedDatabases;
        selectedDatabases.splice(index, 1);
        setFieldValue('databases', newDatabases);
    };

    return (
        <Row>
            <Col>
                <Panel title="Buscar Bases">
                    <FieldWrapper label="Buscar">
                        <input
                            className="form-control"
                            type="search"
                            defaultValue=""
                            onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                    e.preventDefault();
                                    searchDatabases(e.target.value);
                                }
                            }}
                        />
                        <span className="text-muted small">
                            Pressione <code>ENTER</code> para buscar
                        </span>
                    </FieldWrapper>
                    {buscando ? (
                        <LoadingSpinner />
                    ) : (
                        <Table size="sm" bordered hover className="text-center">
                            <thead className="bg-secondary text-dark">
                                <tr>
                                    <th style={{ width: '10rem' }}>ID (Proseleta)</th>
                                    <th>Nome</th>
                                    <th style={{ width: '3rem' }}>-</th>
                                </tr>
                            </thead>
                            <tbody>
                                {databases.length > 0 && (
                                    <tr>
                                        <td colSpan={3}>
                                            <h5 className="text-center p-1">
                                                Exibindo os primeiros 10 resultados
                                            </h5>
                                        </td>
                                    </tr>
                                )}
                                {databases.length === 0 && (
                                    <tr>
                                        <td colSpan={3}>
                                            <h4 className="text-center p-3">
                                                Nenhum resultado encontrado
                                            </h4>
                                        </td>
                                    </tr>
                                )}
                                {databases.map((item, i) => {
                                    if (selectedIds.indexOf(item.id) > -1) {
                                        return <></>;
                                    }

                                    return (
                                        <tr key={i}>
                                            <td>{String(item.id_proseleta).padStart(4, '0')}</td>
                                            <td>
                                                <span className="kt-font-bold">{item.name}</span>
                                            </td>
                                            <td>
                                                <button
                                                    type="button"
                                                    onClick={() => addDatabase(item)}
                                                    className="btn btn-default btn-sm"
                                                >
                                                    +
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    )}
                </Panel>
            </Col>
            <Col>
                <Panel title="Bases Selecionadas">
                    <Table size="sm" bordered hover className="text-center">
                        <thead className="bg-primary text-white">
                            <tr>
                                <th style={{ width: '10rem' }}>ID (Proseleta)</th>
                                <th>Nome</th>
                                <th style={{ width: '3rem' }}>Apagar?</th>
                            </tr>
                        </thead>
                        <tbody>
                            {selectedDatabases.map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{String(item.id_proseleta).padStart(4, '0')}</td>
                                        <td>
                                            <span className="kt-font-bold">{item.name}</span>
                                        </td>
                                        <td>
                                            <button
                                                type="button"
                                                onClick={() => removeDatabase(item.id)}
                                                className="btn btn-default btn-sm"
                                            >
                                                X
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </Panel>
            </Col>
        </Row>
    );
}
