import React from 'react';
import { ToastProvider } from 'react-toast-notifications';

// Css Modules
import 'bootstrap/dist/css/bootstrap.min.css';
import 'style/css/style.bundle.css';
import 'style/css/fonts.css';
import 'style/css/admin.base.css';
import 'style/css/admin.list.css';
import 'style/css/admin.form.css';
import 'style/css/404.css';
import 'style/css/login.css';

// Javascript Modules
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'configs/yup';
import 'style/js/fontawesome';
import { UserContextProvider } from 'context/UserContext';
import CoreRouter from 'routes/routers/CoreRouter';

function App() {
    return (
        <ToastProvider autoDismiss autoDismissTimeout={4000} placement="bottom-right">
            <UserContextProvider>
                <CoreRouter />
            </UserContextProvider>
        </ToastProvider>
    );
}

export default App;
