import React from 'react';

function DashboardContainer({ children }) {
    return (
        <div
            className="kt-container kt-container--fluid
					kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-grid--stretch"
            id="main-container"
        >
            <div
                className="kt-body kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-grid--stretch"
                id="kt_body"
            >
                <div
                    className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
                    id="kt_content"
                >
                    {children}
                </div>
            </div>
        </div>
    );
}

export default DashboardContainer;
