import React from 'react';

export default function DashBoardContainerFluid({ children }) {
    return (
        <div
            className="kt-container kt-container--fluid
								 kt-grid__item kt-grid__item--fluid"
        >
            <div className="row">
                <div className="container-fluid">{children}</div>
            </div>
        </div>
    );
}
