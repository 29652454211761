import { reverse } from 'named-urls';
import { urls } from 'routes/api';
import api from 'helpers/api';

export default class Invoice {
    constructor(uuid) {
        this.uuid = uuid;
    }

    async show() {
        let url = reverse(urls.client.invoice, { uuid: this.uuid });
        const { data } = await api.get(url);
        return data;
    }

    async payment() {
        let url = reverse(urls.client.payment, { uuid: this.uuid });
        const { data } = await api.get(url);
        return data;
    }

    async cancelTransaction() {
        let url = reverse(urls.client.cancel_transaction, { uuid: this.uuid });
        const { data } = await api.post(url);
        return data;
    }
}
