import { urls } from 'routes/app';
import Login from 'pages/auth/login';

export const paths = [
    // Login
    {
        path: urls.auth.login,
        component: Login
    }
];
