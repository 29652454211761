import React from 'react';

export default function FieldWrapper({ label, name, children, size, required }) {
    const SIZES_INPUT = {
        xs: 'col-lg-10 col-md-10 col-sm-12 col-xs-10',
        lg: 'col-lg-8 col-md-8 col-sm-12 col-xs-4',
        md: 'col-lg-4 col-md-5 col-sm-12 col-xs-3',
        sm: 'col-lg-3 col-md-4 col-sm-12 col-xs-2'
    };

    const sizeInput = SIZES_INPUT[size || 'xs'];

    if (!label) {
        return <>{children}</>;
    }

    return (
        <div className={'field-' + name}>
            <div className="form-group row">
                <label className="kt-font-bold col-form-label col-lg-2 col-md-2 col-sm-12">
                    {label}
                    {required && <span className={'text-danger ml-1 kt-font-bold'}>*</span>}
                </label>
                <div className={sizeInput}>{children}</div>
            </div>
        </div>
    );
}
