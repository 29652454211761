import TableLink from 'components/DataTables/TableLink';
import Actions from 'components/Utils/DataTable/Actions';
import Badge from 'components/Utils/DataTable/Badge';
import Email from 'components/Utils/DataTable/Email';
import AdminListLayout from 'layouts/admin/AdminListLayout';
import { reverse } from 'named-urls/src';
import React from 'react';
import { urls } from 'routes/app';
import Client from 'services/admin/client';

export default function ClientList() {
    const title = 'Lista de Clientes';
    const add_url = reverse(urls.admin.client.add);
    const breadcrumbs = [
        {
            label: title,
            url: ''
        }
    ];
    const columns = [
        {
            size: 'id',
            field: 'id',
            label: 'ID',
            render: (row) => (
                <TableLink to={reverse(urls.admin.client.edit, { id_client: row.id })}>
                    {String(row.id).padStart(4, '0')}
                </TableLink>
            )
        },
        {
            field: 'name',
            label: 'Nome'
        },
        {
            field: 'email',
            label: 'Email',
            render: (row) => <Email email={row.email} />
        },
        {
            field: 'phone',
            label: 'Telefone'
        },
        {
            field: 'databases',
            label: 'Base de Dados'
        },
        {
            field: 'status',
            label: 'Ativo?',
            order: 'status',
            render: (row) => (
                <Badge label={row.status} color={row.status === 'Sim' ? 'success' : 'danger'} />
            )
        },
        {
            label: 'Fat. Automática',
            order: 'auto_invoice',
            render: (row) => (
                <Badge
                    label={row.auto_invoice === true ? 'Sim' : 'Não'}
                    color={row.auto_invoice === true ? 'success' : 'danger'}
                />
            )
        },
        {
            field: 'actions',
            label: 'Ações',
            render: (row) => {
                return (
                    <Actions
                        options={[
                            {
                                label: 'Ver Faturas',
                                icon: 'file-invoice',
                                url: reverse(urls.admin.client.invoice.list, {
                                    id_client: row['id']
                                })
                            },
                            {
                                label: 'Edição Avançada',
                                icon: 'cogs',
                                url: reverse(urls.admin.client.database, { id_client: row['id'] })
                            }
                        ]}
                    />
                );
            }
        }
    ];

    return (
        <AdminListLayout
            source={new Client()}
            columns={columns}
            breadcrumbs={breadcrumbs}
            title={title}
            addUrl={add_url}
        />
    );
}
