import React from 'react';
import { urls } from 'routes/app';
import { reverse } from 'named-urls/src';
import AdminListLayout from 'layouts/admin/AdminListLayout';
import TableLink from 'components/DataTables/TableLink';
import { useParams } from 'react-router-dom';
import { money } from 'helpers/format';
import Badge from 'components/Utils/DataTable/Badge';
import ClientInvoice from 'services/admin/client_invoice';
import AlertIcon from 'components/Utils/Alert/AlertIcon';
import moment from 'moment';

export default function ClientInvoiceList() {
    const { id_client } = useParams();
    const title = 'Lista de Faturas';
    const breadcrumbs = [
        {
            label: 'Lista de Clientes',
            url: reverse(urls.admin.client.list)
        },
        {
            label: 'Cliente #' + id_client,
            url: '#'
        },
        {
            label: title,
            url: ''
        }
    ];

    const columns = [
        {
            size: 'id',
            field: 'id',
            label: 'ID',
            render: (row) => {
                const date = moment(row['submission_date'], 'DD/MM/YYYY');

                return (
                    <TableLink
                        to={reverse(urls.admin.invoice.edit, {
                            month: date.month() + 1,
                            year: date.year(),
                            id_invoice: row.id
                        })}
                    >
                        {String(row.id).padStart(6, '0')}
                    </TableLink>
                );
            }
        },
        {
            field: 'client',
            label: 'Cliente',
            render: (row) => (
                <span>
                    {row.client.name} - {row.client.company_name}
                </span>
            )
        },
        {
            field: 'submission_date',
            label: 'Data para Envio'
        },
        {
            field: 'expiration_date',
            label: 'Limite Pagamento'
        },
        {
            field: 'total',
            label: 'Valor Total',
            render: (row) => {
                return money(row['total']);
            }
        },
        {
            field: 'id_status',
            label: 'Status',
            render: (row) => {
                return <Badge {...status[row.id_status]} />;
            }
        }
    ];

    return (
        <AdminListLayout
            source={new ClientInvoice(id_client)}
            columns={columns}
            breadcrumbs={breadcrumbs}
            title={title}
        >
            <AlertIcon>
                Você está vendo a lista de Faturas do <code>Cliente #{id_client}</code>
            </AlertIcon>
        </AdminListLayout>
    );
}
