import React, { useCallback, useContext, useState } from 'react';
import { DatatableContext } from 'components/DataTables/DatatableContext';
import { Col, Row } from 'react-bootstrap';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { configs } from 'configs/list';
import { useToasts } from 'react-toast-notifications';
import { useHistory } from 'react-router-dom';

export default function DatatableBottomActions() {
    const { actions, data } = useContext(DatatableContext);
    const history = useHistory();
    const [action, setAction] = useState('');
    const { ids, setIds } = useContext(DatatableContext);
    const { addToast } = useToasts();

    let triggerAction = useCallback(() => {
        if (action === '') {
            alert(configs.actions.no_selection);
            return;
        }
        let selectedAction = actions[action];
        selectedAction
            .action(ids.ids)
            .then(() => {
                history.go(0);
            })
            .catch(() => {
                const MySwal = withReactContent(Swal);
                MySwal.fire({
                    title: configs.actions.error,
                    text: configs.save_error
                });
            });
    }, [ids, action]);

    if (ids.ids.length === 0) {
        return <></>;
    }

    return (
        <Row className="row alert mt-4 alert-solid-brand alert-bold" role="alert">
            <Col md={4} className={'mt-2'}>
                <span className="kt-font-bold">
                    {ids.ids.length} de {data.length} selecionado(s)
                </span>
            </Col>
            <Col md={8}>
                <div className="d-flex">
                    <select
                        name="action"
                        className="form-control"
                        defaultValue={''}
                        onChange={(e) => setAction(e.target.value)}
                        required=""
                    >
                        <option value="">---------</option>
                        {actions &&
                            actions.map((data, i) => {
                                return (
                                    <option value={i} key={i}>
                                        {data.label}
                                    </option>
                                );
                            })}
                    </select>
                    <button
                        type="button"
                        className="btn btn-label-brand btn-bold"
                        name="index"
                        onClick={() => triggerAction()}
                    >
                        {configs.actions.apply}
                    </button>
                </div>
            </Col>
        </Row>
    );
}
