import { configs } from 'configs/list';
import { addOrRemove } from 'helpers/array';
import * as qs from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const DatatableContext = React.createContext({});

export const DatatableContextProvider = ({
    breadcrumbs,
    title,
    columns,
    topButtons = [],
    children,
    primaryKey = 'id',
    actions,
    source
}) => {
    const toggleId = (id) => {
        setIds({ ids: addOrRemove(ids.ids, id) });
    };

    let location = useLocation();
    const [listActions, setListActions] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [showCheckbox, setShowCheckbox] = useState(false);
    const [ids, setIds] = useState({ ids: [] });
    const [rowData, setRowData] = useState([]);
    const [responseData, setResponseData] = useState([]);
    const [filters, setFilters] = useState([]);
    const [orderable, setOrderable] = useState([]);
    const [paginationData, setPaginationData] = useState([]);
    const [buttons, setButtons] = useState(topButtons);

    // Get actions
    const getDefaultActions = useCallback(() => {
        let defaultActions = [];
        if (typeof source.deleteMany === 'function') {
            defaultActions.push({
                label: configs.default_actions.delete,
                action: (ids) => {
                    return source.deleteMany(ids);
                }
            });
        }
        return defaultActions;
    }, [location]);

    // Get data
    const getData = useCallback(() => {
        let params = qs.parse(window.location.search);
        return source
            .list(params)
            .then((data) => {
                setLoading(false);

                // Row data
                setRowData(data.data);
                setResponseData(data);

                // Pagination
                const { total, last_page } = data.meta;
                let prev = 1;
                let next = 1;

                setPaginationData({
                    total: last_page,
                    count: total,
                    next: next,
                    prev: prev
                });

                // Filters
                setFilters(data.filters);

                // Orderable
                setOrderable(data.orderable);
            })
            .catch(() => {
                setLoading(false);
                setRowData([]);
                setPaginationData([]);
            });
    }, [location]);

    useEffect(() => {
        setIds({ ids: [] });
        let availableActions = getDefaultActions().concat(actions || []);
        setLoading(true);
        setListActions(availableActions);
        setShowCheckbox(!!availableActions);
        getData();
    }, [getData]);

    let values = {
        ids: ids,
        data: rowData,
        responseData: responseData,
        source: source,
        columns: columns,
        primaryKey: primaryKey,
        filters: filters,
        buttons: buttons,
        setButtons: setButtons,
        breadcrumbs: breadcrumbs,
        title: title,
        isLoading: isLoading,
        orderable: orderable,
        pagination: paginationData,
        actions: listActions,
        showCheckbox: showCheckbox,
        setIds: setIds,
        toggleId: toggleId
    };

    return <DatatableContext.Provider value={values}>{children}</DatatableContext.Provider>;
};
