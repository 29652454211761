import React, { useContext } from 'react';
import { DatatableContext } from 'components/DataTables/DatatableContext';

export default function DatatableRowCheckbox({ key, id }) {
    let { toggleId } = useContext(DatatableContext);

    return (
        <td key={key} className="field-datatable action-checkbox-column">
            <label className="kt-checkbox kt-checkbox--solid">
                <input
                    data-datatable-checkbox={id}
                    type="checkbox"
                    onClick={() => {
                        toggleId(id);
                    }}
                />
                <span />
            </label>
        </td>
    );
}
