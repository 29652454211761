import { reverse } from 'named-urls';
import { urls } from 'routes/api';
import api from 'helpers/api';

export default class Dashboard {
    async getData() {
        let url = reverse(urls.admin.dashboard);
        const { data } = await api.get(url);
        return data;
    }
}
