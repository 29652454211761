import React, { useCallback, useEffect, useState } from 'react';
import User from 'services/auth/user';

export const UserContext = React.createContext({});

export function UserContextProvider({ children }) {
    const [header, setHeader] = useState({});
    const [user, setUser] = useState({});
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoaded, setLoaded] = useState(false);

    const getUserData = useCallback(() => {
        // Get UserData
        User.show()
            .then((data) => {
                setUser(data.user);
                setHeader(data.header);
                setIsAuthenticated(true);
                setLoaded(true);
            })
            .catch(() => {
                setIsAuthenticated(false);
                setLoaded(true);
            });
    });

    useEffect(() => {
        getUserData();
    }, []);

    return (
        <UserContext.Provider
            value={{
                header: header,
                user: user,
                isLoaded: isLoaded,
                isAuthenticated: isAuthenticated
            }}
        >
            {children}
        </UserContext.Provider>
    );
}
