import { ResponsiveLine } from '@nivo/line';
import React from 'react';

export default function LineChart({ theme, data }) {
    return (
        <ResponsiveLine
            data={data}
            margin={{ top: 40, right: 50, bottom: 30, left: 50 }}
            xScale={{ type: 'point' }}
            yScale={{ type: 'linear', min: 'auto', max: 'auto', reverse: false }}
            curve="linear"
            axisTop={null}
            axisRight={null}
            colors={{ scheme: theme || 'category10' }}
            lineWidth={2}
            pointSize={7}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={3}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabelYOffset={-14}
            useMesh={true}
            legends={[
                {
                    anchor: 'top-left',
                    direction: 'column',
                    justify: false,
                    translateX: 0,
                    translateY: -30,
                    itemsSpacing: 5,
                    itemDirection: 'left-to-right',
                    itemWidth: 80,
                    itemHeight: 16,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemBackground: 'rgba(0, 0, 0, .03)',
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
        />
    );
}
