import React, { useContext, useEffect } from 'react';
import { DatatableContextProvider } from 'components/DataTables/DatatableContext';
import AdminDatatable from 'layouts/admin/AdminDatatable';
import { configs } from 'configs/list';
import { BreadcrumbContext } from 'components/Breadcrumbs/BreadcrumbContext';

export default function AdminListLayout({
    title = '',
    buttons = [],
    addUrl,
    children,
    breadcrumbs,
    ...props
}) {
    if (addUrl) {
        buttons.push({
            icon: 'plus',
            label: configs.add_button_label,
            url: addUrl
        });
    }

    const { setTitle, setBreadcrumbs } = useContext(BreadcrumbContext);

    useEffect(() => {
        setTitle(title);
        setBreadcrumbs(breadcrumbs);
    }, []);

    return (
        <DatatableContextProvider {...props} topButtons={buttons}>
            {children}
            <AdminDatatable />
        </DatatableContextProvider>
    );
}
