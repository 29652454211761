import MultiSelectField from 'components/Form/Fields/MultiSelectField';
import ReadOnlyField from 'components/Form/Fields/ReadOnlyField';
import SwitchField from 'components/Form/Fields/SwitchField';
import Panel from 'components/Form/Panel';
import PkField from 'components/Utils/Form/PkField';
import ReadOnlyLabel from 'components/Utils/Form/ReadOnlyLabel';
import { useFormikContext } from 'formik';
import AdminFormLayout from 'layouts/admin/AdminFormLayout';
import { reverse } from 'named-urls/src';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { urls } from 'routes/app';
import User from 'services/admin/user';

export default function FormUser() {
    const { id_user } = useParams();
    const title = 'Editar Usuário';

    const formUrls = {
        edit: reverse(urls.admin.user.edit, { id_user: id_user }),
        list: reverse(urls.admin.user.list)
    };

    const breadcrumbs = [
        {
            label: 'Lista de Usuários',
            url: formUrls.list
        },
        {
            label: title,
            url: ''
        }
    ];

    const [options, setOptions] = useState({ permission: [] });

    return (
        <AdminFormLayout
            id={id_user}
            setOptions={setOptions}
            source={new User(id_user)}
            initialValues={{}}
            breadcrumbs={breadcrumbs}
            title={title}
            urls={formUrls}
        >
            <Form options={options} />
        </AdminFormLayout>
    );
}

function Form({ options }) {
    const { values } = useFormikContext();

    return (
        <>
            <Panel>
                <PkField />
                <ReadOnlyField label="Email">
                    <ReadOnlyLabel>
                        <span>{values.email}</span>
                    </ReadOnlyLabel>
                </ReadOnlyField>
                <ReadOnlyField label="Nome">
                    <ReadOnlyLabel>
                        <span>{values.name}</span>
                    </ReadOnlyLabel>
                </ReadOnlyField>
                <SwitchField
                    label="Super Usuário?"
                    name="is_superadmin"
                    help="Marcar esta opção fará com que o usuário tenha todas as permissões."
                />
            </Panel>
            {values.is_superadmin || (
                <Panel title="Permissões">
                    <MultiSelectField
                        choices={options.permissions || []}
                        label="Permissões"
                        name="permissions"
                    />
                </Panel>
            )}
        </>
    );
}
