import React from 'react';
import { Link } from 'react-router-dom';

function HeaderTop({ children, title, url, logo }) {
    return (
        <div className="kt-header__top">
            <div className="kt-container kt-container--fluid">
                <div className="kt-header__brand kt-grid__item" id="kt_header_brand">
                    <div className="kt-header__brand-logo">
                        <Link to={url}>
                            <img alt="Logo" src={logo} className="kt-header__brand-logo-default" />
                            <img alt="Logo" src={logo} className="kt-header__brand-logo-sticky" />
                        </Link>
                    </div>
                    <h1 className="kt-header__brand-title">
                        <span className="text-white">{title}</span>
                    </h1>
                </div>
                <div className="kt-header__topbar">{children}</div>
            </div>
        </div>
    );
}

export default HeaderTop;
