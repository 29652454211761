import React from 'react';
import { urls } from 'routes/app';
import { reverse } from 'named-urls/src';
import { useParams } from 'react-router-dom';
import Service from 'services/admin/service';
import TextField from 'components/Form/Fields/TextField';
import Panel from 'components/Form/Panel';
import PkField from 'components/Utils/Form/PkField';
import AdminFormLayout from 'layouts/admin/AdminFormLayout';
import SwitchField from 'components/Form/Fields/SwitchField';

export default function FormService() {
    const { id_service } = useParams();
    const title = id_service ? 'Editar Serviço' : 'Adicionar Serviço';

    const formUrls = {
        add: reverse(urls.admin.service.add),
        edit: reverse(urls.admin.service.edit, { id_service: id_service }),
        list: reverse(urls.admin.service.list),
        delete: reverse(urls.admin.service.delete, { id_service: id_service })
    };

    const breadcrumbs = [
        {
            label: 'Lista de Serviços',
            url: formUrls.list
        },
        {
            label: title,
            url: ''
        }
    ];

    return (
        <AdminFormLayout
            id={id_service}
            source={new Service(id_service)}
            initialValues={{ has_ref_id: true }}
            breadcrumbs={breadcrumbs}
            title={title}
            urls={formUrls}
        >
            <Panel>
                <PkField />
                <TextField name="name" autoFocus label="Nome" required={true} />
                <SwitchField
                    name="has_ref_id"
                    label="Possui ID?"
                    help="Marque esta opção para quando o item possui identificador. (concurso, editar...)"
                    required={true}
                />
            </Panel>
        </AdminFormLayout>
    );
}
