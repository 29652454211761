import { reverse } from 'named-urls';
import { urls } from 'routes/api';
import api from 'helpers/api';

export default class Database {
    constructor(id_database = 0) {
        this.id_database = id_database;
    }

    async list(params) {
        let url = reverse(urls.admin.database.get);
        const { data } = await api.get(url, { params: params });
        return data;
    }

    async show() {
        let url = reverse(urls.admin.database.edit, { id_database: this.id_database });
        const { data } = await api.get(url);
        return data;
    }

    async delete() {
        let url = reverse(urls.admin.database.edit, { id_database: this.id_database });
        const { data } = await api.delete(url);
        return data;
    }

    async update(values) {
        let url = reverse(urls.admin.database.edit, { id_database: this.id_database });
        const { data } = await api.put(url, values);
        return data;
    }

    async create(values) {
        let url = reverse(urls.admin.database.get);
        const { data } = await api.post(url, values);
        return data;
    }

    async save(values) {
        if (this.id_database > 0) {
            return this.update(values);
        }
        return this.create(values);
    }
}
