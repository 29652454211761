import React, { useState } from 'react';

export const BreadcrumbContext = React.createContext({});

export const BreadcrumbContextProvider = ({ children }) => {
    const [title, setTitle] = useState('');
    const [breadcrumbs, setBreadcrumbs] = useState([]);

    const values = {
        title: title,
        setTitle: setTitle,
        setBreadcrumbs: setBreadcrumbs,
        breadcrumbs: breadcrumbs
    };

    return <BreadcrumbContext.Provider value={values}>{children}</BreadcrumbContext.Provider>;
};
