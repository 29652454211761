import React from 'react';

function Header({ children }) {
    return (
        <div id="kt_header" className="kt-header kt-grid__item">
            {children}
        </div>
    );
}

export default Header;
