import TableLink from 'components/DataTables/TableLink';
import Boolean from 'components/Utils/DataTable/Boolean';
import AdminListLayout from 'layouts/admin/AdminListLayout';
import { reverse } from 'named-urls/src';
import React from 'react';
import { urls } from 'routes/app';
import User from 'services/admin/user';

export default function UserList() {
    const title = 'Lista de Usuários';
    const breadcrumbs = [
        {
            label: title,
            url: ''
        }
    ];
    const columns = [
        {
            size: 'sm',
            field: 'id',
            label: 'ID',
            render: (row) => (
                <TableLink to={reverse(urls.admin.user.edit, { id_user: row.id })}>
                    {String(row.id).padStart(6, '0')}
                </TableLink>
            )
        },
        {
            field: 'name',
            label: 'Nome'
        },
        {
            field: 'email',
            label: 'Email'
        },
        {
            field: 'is_superadmin',
            label: 'Super Admin',
            render: (row) => {
                return <Boolean value={row.is_superadmin} />;
            }
        }
    ];

    return (
        <AdminListLayout
            source={new User()}
            columns={columns}
            breadcrumbs={breadcrumbs}
            title={title}
        />
    );
}
