import React, { useContext } from 'react';
import { UserContext } from 'context/UserContext';
import { Redirect } from 'react-router-dom';
import { reverse } from 'named-urls';
import { urls } from 'routes/app';
import AutoRouter from 'components/Router/AutoRouter';
import { paths } from 'routes/paths/auth';

export default function AuthRouter() {
    const { isAuthenticated } = useContext(UserContext);

    if (isAuthenticated) {
        return <Redirect to={reverse(urls.admin.index)} />;
    }

    return <AutoRouter routes={paths} />;
}
