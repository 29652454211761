import FieldWrapper from 'components/Form/Fields/FieldWrapper';
import React from 'react';

export default function ReadOnlyField(props) {
    return (
        <FieldWrapper
            name={props.name}
            label={props.label}
            size={props.size}
            required={props.required}
        >
            {props.children}
        </FieldWrapper>
    );
}
