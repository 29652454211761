import React from 'react';
import { configs } from 'configs/form';

export default function Panel({ title, description, disabled, children, className, ...props }) {
    return (
        <div className="kt-portlet kt-form kt-form--label-right">
            <fieldset
                disabled={disabled}
                {...props}
                className={'mb-3 kt-container  module aligned ' + className}
            >
                <div className="kt-portlet__head mb-4">
                    <div className="kt-portlet__head-label">
                        <h3 className="kt-portlet__head-title">
                            {title || configs.components.panel.default_title}
                        </h3>
                        {description && <div className="ml-3 description">{description}</div>}
                    </div>
                </div>
                {children}
            </fieldset>
        </div>
    );
}
