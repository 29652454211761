import TableLink from 'components/DataTables/TableLink';
import Badge from 'components/Utils/DataTable/Badge';
import { status } from 'helpers/constants';
import { money } from 'helpers/format';
import AdminListLayout from 'layouts/admin/AdminListLayout';
import moment from 'moment';
import { reverse } from 'named-urls/src';
import React from 'react';
import { urls } from 'routes/app';
import Invoice from 'services/admin/invoice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function InvoiceListAll() {
    const title = 'Lista de Faturas';
    const breadcrumbs = [
        {
            label: title,
            url: ''
        }
    ];

    const columns = [
        {
            size: 'id',
            field: 'id',
            label: 'ID',
            render: (row) => {
                const date = moment(row['submission_date'], 'DD/MM/YYYY');
                return (
                    <TableLink
                        to={reverse(urls.admin.invoice.edit, {
                            month: date.month() + 1,
                            year: date.year(),
                            id_invoice: row.id
                        })}
                    >
                        {String(row.id).padStart(6, '0')}
                    </TableLink>
                );
            }
        },
        {
            field: 'client.name',
            label: 'Cliente',
            ordering: true,
            render: (row) => (
                <span>
                    {row.client.name} - {row.client.company_name}
                </span>
            )
        },
        {
            field: 'submission_date',
            label: 'Data para Envio'
        },
        {
            field: 'expiration_date',
            label: 'Limite Pagamento'
        },
        {
            field: 'total',
            label: 'Valor Total',
            render: (row) => {
                return money(row['total']);
            }
        },
        {
            field: 'link',
            label: 'Link',
            render: (row) => {
                return (
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href={reverse(urls.client.invoice, { uuid: row.uuid })}
                    >
                        Acessar
                        {row.expired && (
                            <span
                                style={{
                                    zoom: 0.7
                                }}
                                className="ml-2 badge-danger badge "
                            >
                                <FontAwesomeIcon icon="exclamation-triangle" />
                            </span>
                        )}
                    </a>
                );
            }
        },
        {
            field: 'id_status',
            label: 'Status',
            render: (row) => {
                return <Badge {...status[row.id_status]} />;
            }
        }
    ];

    return (
        <AdminListLayout
            source={new Invoice()}
            columns={columns}
            breadcrumbs={breadcrumbs}
            title={title}
        />
    );
}
