import React from 'react';
import DatatableTable from 'components/DataTables/DatatableTable';
import DatatableTop from 'components/DataTables/Top/DatatableTop';
import DatatableTopFilters from 'components/DataTables/Top/DatatableTopFilters';
import DatatableTopTools from 'components/DataTables/Top/DatatableTopTools';
import DatatableBottom from 'components/DataTables/Bottom/DatatableBottom';
import DatatableBottomPagination from 'components/DataTables/Bottom/DatatableBottomPagination';
import DatatableBottomActions from 'components/DataTables/Bottom/DatatableBottomActions';

export default function AdminDatatable({ component }) {
    let TableComponent = component || DatatableTable;

    return (
        <div className={'col-12 kt-portlet'}>
            <DatatableTop>
                <DatatableTopFilters />
                <DatatableTopTools />
            </DatatableTop>
            <TableComponent />
            <DatatableBottom>
                <DatatableBottomPagination />
                <DatatableBottomActions />
            </DatatableBottom>
        </div>
    );
}
