import { ErrorMessage, useField, useFormikContext } from 'formik';
import React from 'react';
import FieldWrapper from 'components/Form/Fields/FieldWrapper';
import ErrorField from 'components/Form/Fields/ErrorField';
import HelpText from 'components/Form/Fields/HelpText';
import Select from 'components/Form/Fields/Select';

export default function SelectField({ name, options, help, ...props }) {
    const [field] = useField({ ...props, name });
    const { errors } = useFormikContext();
    field.value = !field.value ? '' : field.value;

    return (
        <FieldWrapper name={name} {...props}>
            <Select
                name={name}
                className={errors[name] ? 'form-control is-invalid' : 'form-control'}
                {...field}
                {...props}
                options={options}
            />
            {help && <HelpText>{help}</HelpText>}
            <ErrorMessage name={name} component={ErrorField} />
        </FieldWrapper>
    );
}
