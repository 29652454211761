import React from 'react';

export default function Switch({ size, name, ...props }) {
    const sizeClass = {
        sm: 'kt-switch--sm ',
        md: ''
    }[size || 'md'];

    return (
        <span
            className={
                sizeClass + 'kt-switch kt-switch--outline kt-switch--icon kt-switch--primary'
            }
        >
            <label className={'mb-0 pb-0'}>
                <input id={'id_' + name} {...props} name={props.name} type={'checkbox'} />
                <span />
            </label>
        </span>
    );
}
