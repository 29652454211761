import React, { useContext } from 'react';
import DatatableColumn from 'components/DataTables/Column/DatatableColumn';
import DatatableColumnCheckbox from 'components/DataTables/Column/DatatableColumnCheckbox';
import DatatableRow from 'components/DataTables/Row/DatatableRow';
import { qString } from 'helpers/querystring';
import { DatatableContext } from 'components/DataTables/DatatableContext';
import { configs } from 'configs/list';

export default function DatatableTable() {
    const { columns, isLoading, data, orderable, showCheckbox } = useContext(DatatableContext);

    if (isLoading) {
        return (
            <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        );
    }

    const search = qString().get('q') || '';
    if (data.length === 0) {
        let message = configs.no_result;
        if (search !== '') {
            message = configs.no_result_search.replace('{q}', '"' + search + '"');
        }

        return (
            <div className="text-center">
                <img src="/img/admin/no_results.svg" height="150px" alt="no_result" />
                <h3 className={'mt-5 ml-3'}>{message}</h3>
            </div>
        );
    }

    return (
        <div className="kt-section__content">
            <table
                className="table kt-datatable__table table-striped table-hover"
                id="changelist-table"
            >
                <thead className="kt-datatable__head">
                    <tr className="kt-datatable__row">
                        {showCheckbox && <DatatableColumnCheckbox />}
                        {columns.map((data, i) => {
                            let isOrderable = false;
                            if (orderable.includes(data.field) || data.ordering) {
                                isOrderable = true;
                            }
                            return (
                                <DatatableColumn
                                    key={i}
                                    isOrderable={isOrderable}
                                    size={data.size}
                                    field={data.field}
                                    label={data.label}
                                />
                            );
                        })}
                    </tr>
                </thead>
                <tbody className="kt-datatable__body">
                    {data &&
                        data.map((data, i) => {
                            return <DatatableRow key={i} data={data} />;
                        })}
                </tbody>
            </table>
        </div>
    );
}
