import HeaderTop from 'components/Header/HeaderTop';
import HeaderUser from 'components/HeaderUser/HeaderUser';
import Header from 'components/Header/Header';
import React, { useContext } from 'react';
import HeaderBottom from 'components/Header/HeaderBottom';
import HeaderMenuItem from 'components/Header/HeaderMenuItem';
import { UserContext } from 'context/UserContext';

export default function AdminHeader() {
    const { header } = useContext(UserContext);
    return (
        <div>
            <Header>
                {header.menu && (
                    <>
                        <HeaderTop
                            logo="/img/admin/logo-header.png"
                            url="/admin"
                            title={header.title}
                        >
                            <HeaderUser {...header.user} />
                        </HeaderTop>
                        <HeaderBottom>
                            {header.menu.map((data, i) => {
                                return <HeaderMenuItem key={i} {...data} />;
                            })}
                        </HeaderBottom>
                    </>
                )}
            </Header>
        </div>
    );
}
