import { reverse } from 'named-urls';
import { urls } from 'routes/api';
import api from 'helpers/api';

export default class Client {
    constructor(id_client = 0) {
        this.id_client = id_client;
    }

    async list(params) {
        let url = reverse(urls.admin.client.get);
        const { data } = await api.get(url, { params: params });
        return data;
    }

    async show() {
        let url = reverse(urls.admin.client.edit, { id_client: this.id_client });
        const { data } = await api.get(url);
        return data;
    }

    async delete() {
        let url = reverse(urls.admin.client.edit, { id_client: this.id_client });
        const { data } = await api.delete(url);
        return data;
    }

    async deleteMany(ids) {
        let url = reverse(urls.admin.client.get);
        const { data } = await api.delete(url, { params: { ids: ids } });
        return data;
    }

    async update(values) {
        let url = reverse(urls.admin.client.edit, { id_client: this.id_client });
        const { data } = await api.put(url, values);
        return data;
    }

    async create(values) {
        let url = reverse(urls.admin.client.get);
        const { data } = await api.post(url, values);
        return data;
    }

    async options() {
        let url = reverse(urls.admin.client.options, { id_client: this.id_client || 0 });
        const { data } = await api.get(url);
        return data;
    }

    async save(values) {
        if (this.id_client > 0) {
            return this.update(values);
        }
        return this.create(values);
    }
}
