import IconField from 'components/Form/Fields/IconField';
import IntegerField from 'components/Form/Fields/IntegerField';
import MoneyField from 'components/Form/Fields/MoneyField';
import SelectField from 'components/Form/Fields/SelectField';
import SwitchField from 'components/Form/Fields/SwitchField';
import TextAreaField from 'components/Form/Fields/TextAreaField';
import TextField from 'components/Form/Fields/TextField';
import Panel from 'components/Form/Panel';
import PkField from 'components/Utils/Form/PkField';
import TabularInline from 'components/Utils/Form/TabularInline';
import { useFormikContext } from 'formik';
import AdminFormLayout from 'layouts/admin/AdminFormLayout';
import { reverse } from 'named-urls/src';
import React, { useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { urls } from 'routes/app';
import Client from 'services/admin/client';

export default function FormClient() {
    const { id_client } = useParams();
    const title = id_client ? 'Editar Cliente' : 'Adicionar Cliente';
    const formUrls = {
        add: reverse(urls.admin.client.add),
        edit: reverse(urls.admin.client.edit, { id_client: id_client }),
        list: reverse(urls.admin.client.list),
        delete: reverse(urls.admin.client.delete, { id_client: id_client })
    };

    const breadcrumbs = [
        {
            label: 'Lista de Clientes',
            url: formUrls.list
        },
        {
            label: title,
            url: ''
        }
    ];

    const [options, setOptions] = useState({});

    return (
        <AdminFormLayout
            id={id_client}
            source={new Client(id_client)}
            setOptions={setOptions}
            validation={{}}
            initialValues={{
                id_status: 1,
                auto_invoice: false,
                constant_value: 80,
                payment_limit_days: 15,
                payment_day: 1,
                variable_value: 1
            }}
            breadcrumbs={breadcrumbs}
            title={title}
            urls={formUrls}
        >
            <Form options={options} id_client={id_client} />
        </AdminFormLayout>
    );
}

function Form({ options }) {
    const { values } = useFormikContext();

    let databases = values.databases || [];

    return (
        <Row>
            <Col md={12} sm={12} lg={12} xl={5}>
                <Panel title="Informações Gerais">
                    <PkField />
                    <TextField name="name" autoFocus label="Nome" required={true} />
                    <IconField
                        prepend="building"
                        required={true}
                        name="company_name"
                        label="Razão Social"
                    />
                    <IconField
                        placeholder="email@exemplo.com"
                        type="email"
                        name="email"
                        label="Email"
                        prepend={'envelope'}
                    />
                    <IconField
                        name="phone"
                        mask={'(99) 99999-9999'}
                        placeholder="(00) 00000-0000"
                        label="Telefone"
                        prepend={'phone'}
                    />
                    <TextField
                        label="CNPJ"
                        required={true}
                        name="cnpj"
                        mask={'99.999.999/9999-99'}
                    />
                    <TextField name="address" label="Endereço" />
                    <TextField name="site" label="Site" />
                    <SelectField
                        name="id_status"
                        required={true}
                        options={options.id_status || []}
                        label="Status"
                    />
                    <TextAreaField name="extra" label="Observações" />
                    <SwitchField name="auto_invoice" label="Fatura Automática?" />
                </Panel>
                {values.auto_invoice === true && (
                    <Panel title="Configuração de Contrato">
                        <MoneyField required={true} name="variable_value" label="Valor Variável" />
                        <MoneyField required={true} name="constant_value" label="Valor Constante" />
                        <IntegerField
                            name="payment_day"
                            required={true}
                            help="Dia que será preenchido automaticamente quando a fatura for criada."
                            label="Dia da Fatura"
                        />
                        <IntegerField
                            name="payment_limit_days"
                            required={true}
                            help="Dia em que a fatura irá vencer."
                            label="Dia de Vencimento da fatura"
                        />
                    </Panel>
                )}
            </Col>
            <Col md={12} sm={12} lg={12} xl={7}>
                <Panel title="Databases">
                    <Table hover bordered className="text-center">
                        <thead className="text-white bg-primary">
                            <tr>
                                <th style={{ width: '9rem' }}>ID (Proseleta)</th>
                                <th>Nome</th>
                            </tr>
                        </thead>
                        <tbody>
                            {databases.length === 0 && (
                                <tr>
                                    <td colSpan={2}>
                                        <h5 className="text-center text-primary pt-1">
                                            Nenhum banco configurado
                                        </h5>
                                    </td>
                                </tr>
                            )}
                            {databases.map((item, i) => (
                                <tr key={i}>
                                    <td>{String(item.id_proseleta).padStart(6, '0')}</td>
                                    <td>{item.name}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Panel>
                <Panel title={'Contatos'}>
                    <TabularInline
                        options={values.contacts || []}
                        pk="id"
                        name="contacts"
                        columns={[
                            {
                                label: 'Nome',
                                render: (row, i) => {
                                    return <TextField name={`contacts.${i}.name`} />;
                                }
                            },
                            {
                                label: 'Telefone',
                                render: (row, i) => {
                                    return (
                                        <TextField
                                            mask={'(99) 99999-9999'}
                                            name={`contacts.${i}.phone`}
                                        />
                                    );
                                }
                            },
                            {
                                label: 'Recebe Email (CC)',
                                render: (row, i) => {
                                    return <SwitchField name={`contacts.${i}.receive_email`} />;
                                }
                            },
                            {
                                label: 'Email',
                                render: (row, i) => {
                                    return (
                                        <TextField type={'email'} name={`contacts.${i}.email`} />
                                    );
                                }
                            }
                        ]}
                        newValues={{
                            phone: '',
                            email: '',
                            name: ''
                        }}
                    />
                </Panel>
            </Col>
        </Row>
    );
}
