import { BreadcrumbContext } from 'components/Breadcrumbs/BreadcrumbContext';
import FocusError from 'components/Form/FocusError';
import SubmitRow from 'components/Form/SubmitRow';
import LoadingSpinner from 'components/Utils/Spinner/LoadingSpinner';
import { configs } from 'configs/form';
import { Form, Formik } from 'formik';
import { DisplayFormikState } from 'helpers/form';
import * as qs from 'query-string';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export default function AdminFormLayout({
    id,
    title,
    breadcrumbs,
    children,
    readOnly,
    source,
    setOptions,
    initialValues,
    urls
}) {
    const history = useHistory();
    const { addToast } = useToasts();
    const [initial, setInitial] = useState(initialValues);
    const [isDataLoaded, setDataLoaded] = useState(false);
    const [isOptionsLoaded, setOptionsLoaded] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);
    const { setTitle, setBreadcrumbs } = useContext(BreadcrumbContext);

    // Get data from api
    const getData = () => {
        setDataLoaded(false);
        if (!id) {
            setDataLoaded(true);
            return;
        }

        return source
            .show()
            .then((data) => {
                setInitial(data.data);
            })
            .finally(() => {
                setDataLoaded(true);
            });
    };

    // Get options from api
    const getOptions = () => {
        if (!setOptions || typeof source.options !== 'function') {
            setOptionsLoaded(true);
            return;
        }
        return source
            .options(id)
            .then((data) => {
                if (setOptions && data.options) {
                    setOptions(data.options);
                }
            })
            .finally(() => {
                setOptionsLoaded(true);
            });
    };

    // redirect
    function redirect(id, action, data, actions) {
        let params = qs.parse(window.location.search);
        let filters = decodeURIComponent(params['_filters'] ? params['_filters'] : '');

        // Custom Redir
        if (urls.add && action === '_addanother') {
            actions.resetForm(initialValues);
        } else if (urls.list && action === '_save') {
            history.push({ pathname: urls.list, search: filters });
        } else if (urls.list) {
            history.push({
                pathname: urls.list + id + configs.edit_sufix,
                search: location.search
            });
            actions.setValues(data);
        } else {
            history.push({ pathname: urls.edit, search: location.search });
            actions.setValues(data);
        }
    }

    // submit form
    async function submitForm(values, actions) {
        setSubmitting(true);
        source
            .save(values)
            .then(({ data }) => {
                addToast(configs.save_success, {
                    appearance: configs.toast_appearance,
                    autoDismiss: true
                });
                setSubmitting(false);
                redirect(data.id, values.action, data, actions);
            })
            .catch((error) => {
                setSubmitting(false);
                if (error.response && error.response.status === 422) {
                    actions.setErrors(error.response.data.errors);
                    addToast('Ops... Corrija os campos indicados.', {
                        appearance: 'error',
                        autoDismiss: true
                    });
                } else {
                    const MySwal = withReactContent(Swal);
                    MySwal.fire({
                        title: configs.save_error_title,
                        text: configs.save_error
                    });
                }
            });
    }

    useEffect(() => {
        getData();
        getOptions();
        setTitle(title);
        setBreadcrumbs(breadcrumbs);
    }, [id]);

    const FormikValues = () => {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            return <DisplayFormikState />;
        }
        return <></>;
    };

    const isLoaded = isDataLoaded && isOptionsLoaded;

    if (!isLoaded) {
        return (
            <div>
                <LoadingSpinner />
            </div>
        );
    }

    return (
        <>
            <div>
                <Formik
                    initialValues={initial}
                    validateOnBlur={true}
                    validateOnChange={false}
                    onSubmit={submitForm}
                    enableReinitialize={true}
                >
                    <Form autoComplete="off" id="form-crud">
                        {children}
                        {isSubmitting || (
                            <SubmitRow readOnly={readOnly} urls={urls} source={source} />
                        )}
                        {isSubmitting && <LoadingSpinner />}
                        <FormikValues />
                        <FocusError />
                    </Form>
                </Formik>
            </div>
        </>
    );
}
