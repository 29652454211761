import React from 'react';

export default function LoadingSpinner() {
    return (
        <div className="submit-row align-baseline text-primary text-center">
            <div className="spinner-border text-primary" role="status">
                <span className="sr-only text-primary">Loading...</span>
            </div>
        </div>
    );
}
