import HeaderUserItem from 'components/HeaderUser/HeaderUserItem';
import React from 'react';
import Avatar from 'react-avatar';

function HeaderUserMountItems(item) {
    return <HeaderUserItem label={item.label} icon={item.icon} url={item.url} />;
}

function HeaderUser({ avatar, username, menu, subtitle }) {
    return (
        <div className={'kt-header__topbar-item kt-header__topbar-item--user '}>
            <div
                className="kt-header__topbar-wrapper"
                data-toggle="dropdown"
                data-offset="10px,10px"
            >
                <Avatar name={username} size="40" round={true} />
            </div>
            <div
                className={
                    'dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-md '
                }
            >
                <div className="kt-user-card-v4 kt-user-card-v4--skin-light kt-notification-item-padding-x">
                    <div className="kt-user-card-v4__avatar">
                        <Avatar name={username} size="40" round={true} />
                    </div>
                    <div className="kt-user-card-v4__name">
                        {username}
                        <small>{subtitle}</small>
                    </div>
                </div>
                <ul className="kt-nav kt-margin-b-10">
                    {menu &&
                        menu.map(function (data, i) {
                            return <HeaderUserMountItems key={i} {...data} />;
                        })}
                </ul>
            </div>
        </div>
    );
}

export default HeaderUser;
