import React from 'react';

export default function DatatableColumnCheckbox() {
    const selectAll = function () {
        let inputs = document.querySelectorAll('[data-datatable-checkbox]');
        let selectAllCheckbox = document.querySelectorAll('[data-select-all]')[0];
        for (let input of inputs) {
            if (input.checked !== selectAllCheckbox.checked) {
                input.click();
            }
        }
    };

    return (
        <td className="action-checkbox-column">
            <label className="kt-checkbox kt-checkbox--solid">
                <input type="checkbox" data-select-all="true" onClick={() => selectAll()} />
                <span />
            </label>
            <div className="clear" />
        </td>
    );
}
