import Icon from 'components/Utils/Icon';
import LoadingSpinner from 'components/Utils/Spinner/LoadingSpinner';
import { configs } from 'configs/form';
import { money } from 'helpers/format';
import Page404 from 'pages/error/Page404';
import React, { useCallback, useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import { useParams } from 'react-router-dom';
import Invoice from 'services/client/invoice';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export default function PayInvoice() {
    const { uuid } = useParams();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const [invalid, setInvalid] = useState(false);
    const swal = withReactContent(Swal);
    const [loadingQr, setLoadingQr] = useState(false);
    const [loadingBoleto, setLoadingBoleto] = useState(false);

    const getInvoice = useCallback(() => {
        new Invoice(uuid)
            .show()
            .then(({ data }) => {
                setData(data);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
                setInvalid(true);
            });
    }, [uuid]);

    useEffect(() => {
        getInvoice();
    }, [uuid]);

    if (loading) {
        return <LoadingSpinner />;
    }

    if (invalid) {
        return <Page404 />;
    }

    const handleShowPayment = (type) => {
        if (type === 'boleto') {
            setLoadingBoleto(true);
        } else {
            setLoadingQr(true);
        }
        new Invoice(uuid)
            .payment()
            .then(({ path, pix }) => {
                if (type === 'boleto') {
                    location.href = path;
                    return;
                }
                swal.fire({
                    title: 'QR Code - Pix',
                    html: <QRCode value={pix} />
                });
            })
            .catch(() => {
                swal.fire({
                    title: 'Erro',
                    icon: 'warning',
                    iconColor: 'red',
                    text:
                        'Ops... Parece que houve um erro na geração do boleto,' +
                        ' entre em contato com o financeiro.'
                });
            })
            .finally(() => {
                setLoadingBoleto(false);
                setLoadingQr(false);
            });
    };

    const cancelTransaction = () => {
        const swal = withReactContent(Swal);
        swal.fire({
            icon: 'warning',
            iconColor: 'red',
            title: 'Aviso',
            text: 'Deseja cancelar o boleto gerado?',
            showCancelButton: true,
            focusCancel: true,
            focusConfirm: false,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Sim, eu quero',
            cancelButtonText: 'Não'
        }).then(({ isConfirmed }) => {
            if (isConfirmed) {
                new Invoice(uuid)
                    .cancelTransaction()
                    .then(() => location.reload())
                    .catch((e) => {
                        console.log(e);
                        alert('Ocorreu um erro ao cancelar a fatura. Contate o financeiro.');
                    });
            }
        });
    };

    const ConteudoFatura = () => (
        <>
            <div className="mb-3">
                <img
                    className={'mb-5'}
                    style={{ width: '200px' }}
                    src={'/img/admin/logo.png'}
                    alt={'logo'}
                />
                <div className="pt-3 pb-3">
                    <h3 className="mt-1 pb-1">
                        <span>Fatura # {data.id} - </span>
                        <span className="text-primary">
                            {data?.client?.company_name || data?.client?.name || '-'}
                        </span>
                    </h3>
                    <h5>Data de Referência: {data.expiration_date} </h5>{' '}
                    {data.expired && (
                        <span className="ml-2 badge-danger badge">
                            {' '}
                            <Icon icon="exclamation-triangle" /> Em Atraso
                        </span>
                    )}
                </div>
            </div>

            <table className="table">
                <thead>
                    <tr className="bg-white text-dark">
                        <th scope="col">#</th>
                        <th scope="col">Descrição</th>
                        <th scope="col">Quantidade</th>
                        <th scope="col">Valor</th>
                        <th scope="col">Total</th>
                    </tr>
                </thead>
                <tbody className="kt-font-medium">
                    {data.items &&
                        data.items.map((data, i) => (
                            <tr key={i}>
                                <th scope="row">{i + 1}</th>
                                <td>{data.description}</td>
                                <td>{data.quantity}</td>
                                <td>{money(data.value)}</td>
                                <td>{money(data.quantity * data.value)}</td>
                            </tr>
                        ))}
                </tbody>
            </table>
            <h3 className="text-dark mt-4 mb-1 kt-font-bold">
                Total da Fatura: {money(data.total)}
            </h3>
            <div className="mt-5">
                <div>
                    <div className="alert alert-bold alert-solid-danger">
                        <div className="alert-text">
                            Caso ocorra erro na hora de pagar o boleto pelo aplicativo do banco,
                            aguarde alguns minutos e tente novamente
                        </div>
                    </div>
                </div>

                {data.id_status !== 4 && !data.has_delayed_transaction && (
                    <>
                        <button
                            onClick={() => handleShowPayment('boleto')}
                            className={'btn btn-lg mr-3 btn-primary btn-elevate'}
                        >
                            {loadingBoleto || (
                                <>
                                    <Icon icon={'print'} />
                                    Imprimir Boleto
                                </>
                            )}
                            {loadingBoleto && (
                                <span
                                    className="spinner-border spinner-border-sm mr-2"
                                    role="status"
                                    aria-hidden="true"
                                />
                            )}
                        </button>
                        <button
                            onClick={() => handleShowPayment('pix')}
                            className={'btn btn-lg mr-3 btn-default btn-elevate'}
                        >
                            {loadingQr || (
                                <>
                                    <Icon icon={'qrcode'} />
                                    Pagar com PIX
                                </>
                            )}
                            {loadingQr && (
                                <span
                                    className="spinner-border spinner-border-sm mr-2"
                                    role="status"
                                    aria-hidden="true"
                                />
                            )}
                        </button>
                    </>
                )}
                {data.id_status === 4 && (
                    <div>
                        <img src="/img/admin/checked.svg" alt="checked" height="70" />
                        <h4 className="kt-font-bold mt-3">Pagamento Confirmado</h4>
                    </div>
                )}
                {data.has_delayed_transaction && (
                    <>
                        <h4 className="text-danger text-center mt-5 mb-3">
                            Esta fatura está em atraso, caso você ainda não tenha pago, clique no
                            botão abaixo para gerar uma nova fatura.
                        </h4>
                        <button onClick={() => cancelTransaction()} className="btn btn-danger mt-4">
                            GERAR FATURA NOVAMENTE
                        </button>
                    </>
                )}
            </div>
        </>
    );

    return (
        <div>
            <div
                className="align-self-center w-100"
                style={{
                    marginTop: '13px',
                    marginLeft: '14px',
                    marginRight: '14px'
                }}
            >
                <div className="mx-auto w-100" style={{ maxWidth: '1000px' }}>
                    <div className="kt-portlet">
                        <div className="kt-portlet__body m-5 text-center">
                            <ConteudoFatura />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
