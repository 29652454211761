import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function ErrorField({ children }) {
    return (
        <div className="invalid-feedback d-block">
            <div className="errorlist" style={{ fontSize: '10px' }}>
                <FontAwesomeIcon
                    icon={'circle'}
                    className={'ml-4 mr-1'}
                    style={{ fontSize: '6px' }}
                />
                {children}
            </div>
        </div>
    );
}
