import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

function BreadcrumbItem(props) {
    if (props.home) {
        return (
            <Link to={props.url} className="kt-subheader__breadcrumbs-link">
                <FontAwesomeIcon icon={['fas', props.icon]} />
                <span style={{ marginLeft: '0.35rem' }}>{props.label}</span>
            </Link>
        );
    }

    if (props.url !== '' && props.url !== '#') {
        return (
            <Link className="kt-subheader__breadcrumbs-link" to={props.url}>
                {'• ' + props.label}
            </Link>
        );
    }

    return (
        <span className="kt-subheader__breadcrumbs-link breadcrumb-active">
            {'• ' + props.label}
        </span>
    );
}

export default BreadcrumbItem;
