import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

function HeaderUserItem(props) {
    return (
        <li className="kt-nav__item">
            <Link to={props.url} className="kt-nav__link">
                <span className="kt-nav__link-icon">
                    <FontAwesomeIcon icon={props.icon} />
                </span>
                <span className="kt-nav__link-text">{props.label}</span>
            </Link>
        </li>
    );
}

export default HeaderUserItem;
