const APP_TOKEN_KEY = '@ps-adm-token';
const APP_EXPIRE_KEY = '@ps-adm-expire';
import moment from 'moment';

export function getJWTData() {
    const token = localStorage.getItem(APP_TOKEN_KEY);
    const expireAt = localStorage.getItem(APP_EXPIRE_KEY);

    if (!token || !expireAt) {
        return null;
    }

    let now = moment(new Date());
    let expire = moment(expireAt);

    if (now.isAfter(expire)) {
        clearJWTData();
        return null;
    }

    return { token };
}

export function setJWTData(token) {
    localStorage.setItem(APP_TOKEN_KEY, token);
    let expireAt = moment().add(1, 'd').format();
    localStorage.setItem(APP_EXPIRE_KEY, expireAt);
}

export function clearJWTData() {
    localStorage.removeItem(APP_TOKEN_KEY);
    localStorage.removeItem(APP_EXPIRE_KEY);
}
