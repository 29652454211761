import React from 'react';

export default function ProgressList({ total, data }) {
    if (!data) {
        return <></>;
    }

    return (
        <div className="kt-widget-11 m-2">
            {data &&
                data.map((item, i) => {
                    let percent = (item.value / total) * 100;

                    return (
                        <div key={i} className="kt-widget-11__item">
                            <div className="kt-widget-11__wrapper">
                                <div className="kt-widget-11__title">{item.label}</div>
                                <div className="kt-widget-11__value">
                                    {item.value} / <span className="kt-opacity-5">{total}</span>
                                </div>
                            </div>
                            <div className="kt-widget-11__progress">
                                <div className="progress">
                                    <div
                                        className={'progress-bar bg-' + item.color}
                                        role="progressbar"
                                        style={{ width: percent + '%' }}
                                        aria-valuenow={percent}
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                    ></div>
                                </div>
                            </div>
                        </div>
                    );
                })}
        </div>
    );
}
