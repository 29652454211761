import { ErrorMessage, useField } from 'formik';
import React from 'react';
import FieldWrapper from 'components/Form/Fields/FieldWrapper';
import HelpText from 'components/Form/Fields/HelpText';
import ErrorField from 'components/Form/Fields/ErrorField';

export default function SwitchField({
    name,
    required,
    size,
    label,
    help,
    color = 'primary',
    ...props
}) {
    const [field] = useField(name);

    const sizeClass = {
        sm: 'kt-switch--sm ',
        md: ''
    }[size || 'md'];

    return (
        <FieldWrapper name={name} required={required} label={label}>
            <span
                className={
                    sizeClass + 'kt-switch kt-switch--outline kt-switch--icon kt-switch--primary'
                }
            >
                <label className={'mb-0 pb-0'}>
                    <input
                        checked={field.value || false}
                        {...props}
                        id={'id_' + name}
                        name={name}
                        type="checkbox"
                        {...field}
                    />
                    <span />
                </label>
            </span>
            <ErrorMessage name={name} component={ErrorField} />
            {help && <HelpText>{help}</HelpText>}
        </FieldWrapper>
    );
}
