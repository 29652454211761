import React from 'react';

export default function Select({ options, className, ...props }) {
    return (
        <select className={className || 'form-control'} {...props}>
            {options &&
                options.map((data, i) => <option value={data.value} label={data.label} key={i} />)}
        </select>
    );
}
