import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { DatatableContext } from 'components/DataTables/DatatableContext';

function DatatableTopButtons() {
    const { buttons } = useContext(DatatableContext);

    if (!buttons) {
        return <></>;
    }

    return (
        <div className="kt-portlet__head-wrapper ml-3" id="object-tools">
            {buttons.map((item, i) => (
                <div key={i}>
                    <Link
                        to={item.url}
                        className={
                            'btn btn-upper btn-bold ' + (item.class || 'btn-label btn-label-brand')
                        }
                    >
                        <FontAwesomeIcon icon={item.icon} style={{ marginRight: '5px' }} />
                        {item.label}
                    </Link>
                </div>
            ))}
        </div>
    );
}

export default DatatableTopButtons;
