import React, { useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormikContext } from 'formik';
import { configs } from 'configs/form';
import { useToasts } from 'react-toast-notifications';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import * as qs from 'query-string';

export default function SubmitRow({ urls, readOnly, source }) {
    const { setFieldValue, isSubmitting } = useFormikContext();
    let message = configs.components.submit_row;
    let params = qs.parse(window.location.search);
    let filters = decodeURIComponent(params['_filters'] ? params['_filters'] : '');
    const history = useHistory();
    const { addToast } = useToasts();

    const deleteItem = useCallback(() => {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
            icon: 'warning',
            iconColor: 'red',
            title: configs.components.submit_row.delete_message,
            text: configs.components.submit_row.delete_text,
            showCancelButton: true,
            focusCancel: true,
            focusConfirm: false,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: configs.components.submit_row.delete_confirm,
            cancelButtonText: configs.components.submit_row.delete_cancel
        }).then(({ isConfirmed }) => {
            if (isConfirmed) {
                source
                    .delete()
                    .then(() => {
                        history.push(urls.list);
                        addToast(configs.components.submit_row.delete_success, {
                            appearance: 'info',
                            autoDismiss: true
                        });
                    })
                    .catch(() => {
                        addToast(configs.components.submit_row.delete_error, {
                            appearance: 'error',
                            autoDismiss: true
                        });
                    });
            }
        });
    }, [urls]);

    if (readOnly) {
        return (
            <div className="submit-row align-baseline">
                <div className="float-left">
                    {urls.list && (
                        <Link
                            className="kt-font-bold ml-3 pt-2"
                            style={{ fontSize: '1.2rem' }}
                            to={{ pathname: urls.list, search: filters }}
                        >
                            {message.back}
                        </Link>
                    )}
                </div>
            </div>
        );
    }

    return (
        <div className="submit-row align-baseline">
            <div className="float-left">
                <input
                    className="btn btn-primary"
                    type="submit"
                    disabled={isSubmitting}
                    onClick={() => setFieldValue('action', '_save')}
                    value={message.save}
                    name="_save"
                />
                {urls.add && (
                    <input
                        className="btn btn-secondary"
                        type="submit"
                        disabled={isSubmitting}
                        onClick={() => setFieldValue('action', '_addanother')}
                        value={message.save_add}
                        name="_addanother"
                    />
                )}
                {urls.list && (
                    <input
                        className="btn btn-secondary"
                        type="submit"
                        disabled={isSubmitting}
                        onClick={() => setFieldValue('action', '_continue')}
                        value={message.save_continue}
                        name="_continue"
                    />
                )}

                {urls.list && (
                    <Link
                        className="kt-font-bold ml-3 pt-2"
                        style={{ fontSize: '1.2rem' }}
                        to={{ pathname: urls.list, search: filters }}
                    >
                        {message.back}
                    </Link>
                )}
            </div>
            {typeof source.delete === 'function' && (
                <button onClick={deleteItem} type="button" className="btn btn-danger btn-icon-md">
                    <FontAwesomeIcon icon="trash" /> {message.delete}
                </button>
            )}
        </div>
    );
}
