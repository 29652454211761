import React from 'react';
import { urls } from 'routes/app';
import { reverse } from 'named-urls/src';
import Service from 'services/admin/service';
import AdminListLayout from 'layouts/admin/AdminListLayout';
import TableLink from 'components/DataTables/TableLink';
import Settings from 'services/admin/settings';

export default function SettingsList() {
    const title = 'Lista de Configurações';
    const breadcrumbs = [
        {
            label: title,
            url: ''
        }
    ];
    const columns = [
        {
            size: 'sm',
            field: 'id',
            label: 'ID',
            render: (row) => (
                <TableLink to={reverse(urls.admin.settings.edit, { id_setting: row.id })}>
                    {String(row.id).padStart(6, '0')}
                </TableLink>
            )
        },
        {
            size: 'md',
            field: 'key',
            label: 'Chave'
        },
        {
            field: 'value',
            label: 'Valor'
        }
    ];

    return (
        <AdminListLayout
            source={new Settings()}
            columns={columns}
            breadcrumbs={breadcrumbs}
            title={title}
        />
    );
}
