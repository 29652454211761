import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useHistory } from 'react-router-dom';

function Label({ options, selected, label }) {
    for (let data of options) {
        if (selected === String(data.value) && data.value !== '') {
            return <strong className="badge badge-primary">{data.label}</strong>;
        }
    }
    return <span className="kt-font-sm"> {label} </span>;
}

function DatatableTopFilterItem({ label, options, query }) {
    const history = useHistory();
    const searchParams = new URLSearchParams(window.location.search);
    const selected = searchParams.get(query);

    const setFilter = (queryFilter, filterValue) => {
        if (filterValue !== '') {
            searchParams.set(queryFilter, filterValue);
            searchParams.set('page', '1');
        } else {
            searchParams.delete(queryFilter);
        }
        history.push(`?${searchParams.toString()}`);
    };

    return (
        <div className="dropdown ml-1">
            <button
                type="button"
                className="btn btn-default btn-bold btn-font-sm btn-upper"
                data-toggle="dropdown"
            >
                <FontAwesomeIcon className="filter-icon" icon={['fas', 'filter']} />
                <Label label={label} options={options} selected={selected} />
                <FontAwesomeIcon className="filter-icon-down" icon={['fas', 'arrow-down']} />
            </button>
            <div className="dropdown-menu">
                <ul className="kt-nav">
                    {options.map((data, i) => {
                        let selectedClass = '';
                        if (selected === String(data.value)) {
                            selectedClass = 'kt-bg-light-primary';
                        }
                        return (
                            <li
                                key={i}
                                className={
                                    'kt-nav__item text-primary kt-font-bold ' + selectedClass
                                }
                            >
                                <a
                                    className="kt-nav__link text-primary"
                                    onClick={() => {
                                        setFilter(query, data.value);
                                    }}
                                    title={data.label}
                                >
                                    <span className="kt-nav__link-text">{data.label}</span>
                                </a>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
}

export default DatatableTopFilterItem;
