import React from 'react';

function DashboardBody({ children }) {
    return (
        <div className="kt-header--fixed kt-header--minimize-menu kt-subheader--enabled kt-subheader--transparent">
            <div className="kt-grid kt-grid--hor kt-grid--root">
                <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                    <div className="kt-grid__item kt-grid__item--fluid kt-wrapper" id="kt_wrapper">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DashboardBody;
