import React from 'react';
import { urls } from 'routes/app';
import { reverse } from 'named-urls/src';
import AdminListLayout from 'layouts/admin/AdminListLayout';
import Database from 'services/admin/database';
import Boolean from 'components/Utils/DataTable/Boolean';
import TableLink from 'components/DataTables/TableLink';

export default function DatabaseList() {
    const title = 'Lista de Base de Dados';
    const add_url = reverse(urls.admin.database.add);
    const breadcrumbs = [
        {
            label: title,
            url: ''
        }
    ];
    const columns = [
        {
            size: 'sm',
            field: 'id',
            label: 'ID',
            render: (row) => {
                return (
                    <TableLink to={reverse(urls.admin.database.edit, { id_database: row.id })}>
                        {String(row.id).padStart(6, '0')}
                    </TableLink>
                );
            }
        },
        {
            size: 'sm',
            field: 'id_proseleta',
            label: 'ID Proseleta'
        },
        {
            field: 'name',
            label: 'Nome'
        },
        {
            field: 'is_active',
            label: 'Ativo',
            render: (row) => {
                return <Boolean value={row.is_active} />;
            }
        }
    ];

    return (
        <AdminListLayout
            source={new Database()}
            columns={columns}
            breadcrumbs={breadcrumbs}
            title={title}
            addUrl={add_url}
        />
    );
}
