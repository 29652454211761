import { library } from '@fortawesome/fontawesome-svg-core';

// import your icons
import {
    faAngleDoubleLeft,
    faPrint,
    faFilePdf,
    faAngleDoubleRight,
    faAngleDown,
    faAngleLeft,
    faAngleRight,
    faArrowDown,
    faArrowRight,
    faArrowUp,
    faBars,
    faBuilding,
    faCalendar,
    faCalendarAlt,
    faCalendarPlus,
    faCaretLeft,
    faCaretRight,
    faCheck,
    faCircle,
    faClock,
    faCog,
    faCogs,
    faComments,
    faDatabase,
    faDotCircle,
    faEllipsisH,
    faEllipsisV,
    faEnvelope,
    faFileCsv,
    faFilter,
    faHandshake,
    faHome,
    faHospital,
    faHospitalAlt,
    faIdBadge,
    faInfoCircle,
    faList,
    faMale,
    faMoneyBill,
    faPen,
    faPencilAlt,
    faPhone,
    faPlus,
    faPlusCircle,
    faSearch,
    faSignOutAlt,
    faTimes,
    faTools,
    faTrash,
    faUser,
    faUserMd,
    faUserPlus,
    faUsers,
    faQrcode,
    faKey,
    faFileInvoice,
    faBox,
    faPercentage,
    faExclamationTriangle,
    faRocket
} from '@fortawesome/free-solid-svg-icons';

library.add(
    faBars,
    faFileCsv,
    faCaretRight,
    faTrash,
    faPercentage,
    faComments,
    faCaretLeft,
    faExclamationTriangle,
    faKey,
    faPrint,
    faBox,
    faFileInvoice,
    faFilePdf,
    faQrcode,
    faDatabase,
    faRocket,
    faEllipsisV,
    faAngleLeft,
    faAngleDoubleLeft,
    faAngleRight,
    faAngleDoubleRight,
    faCogs,
    faEllipsisH,
    faUserPlus,
    faTimes,
    faAngleDown,
    faEnvelope,
    faCheck,
    faInfoCircle,
    faDotCircle,
    faHandshake,
    faPen,
    faPhone,
    faCog,
    faList,
    faUserMd,
    faHospitalAlt,
    faFilter,
    faUser,
    faUserMd,
    faIdBadge,
    faCircle,
    faCalendar,
    faCalendarPlus,
    faHospital,
    faMale,
    faMoneyBill,
    faTools,
    faBuilding,
    faSignOutAlt,
    faSearch,
    faArrowRight,
    faUserMd,
    faArrowDown,
    faArrowUp,
    faClock,
    faUsers,
    faHome,
    faPlus,
    faPlusCircle,
    faCalendarAlt,
    faPencilAlt
);
