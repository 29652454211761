import React from 'react';

export default function LoginLayout({ title, children }) {
    return (
        <div className="kt-login-v2--enabled">
            <div
                className="kt-grid kt-grid--ver kt-grid--root"
                style={{
                    height: '100vh'
                }}
            >
                <div
                    className="kt-grid__item   kt-grid__item--fluid kt-grid  kt-grid kt-grid--hor kt-login-v2"
                    id="kt_login_v2"
                >
                    <div className="kt-grid__item  kt-grid  kt-grid--ver  kt-grid__item--fluid">
                        <div className="kt-login-v2__body">
                            <div className="kt-login-v2__wrapper">
                                <div
                                    className="kt-login-v2__container"
                                    style={{
                                        backgroundColor: 'white'
                                    }}
                                >
                                    <div className="kt-login-v2__title">
                                        <h3>{title}</h3>
                                        <div className="kt-login-v2__head">
                                            <div className="d-block m-auto kt-login-v2__logo">
                                                <img src="/img/admin/logo.png" alt="logo" />
                                            </div>
                                        </div>
                                    </div>
                                    {children}
                                </div>
                            </div>
                            <div className="kt-login-v2__image">
                                <img
                                    style={{ maxWidth: '30rem' }}
                                    src="/img/auth/bg.svg"
                                    alt="bg-image"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
