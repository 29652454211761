import { include } from 'named-urls';

export const urls = {
    auth: include('/api/auth', {
        login: 'login',
        logout: 'logout',
        user: 'user'
    }),
    client: include('/api/client', {
        invoice: 'invoice/:uuid',
        payment: 'invoice/:uuid/payment',
        cancel_transaction: 'invoice/:uuid/cancel-transaction'
    }),
    admin: include('/api/admin', {
        change_password: 'change-password',
        dashboard: 'dashboard',
        client: include('client', {
            get: '',
            edit: ':id_client/',
            options: 'options/:id_client/',
            database: include('database', {
                get: '',
                edit: ':id_client/',
                options: 'options'
            }),
            invoice: include(':id_client/invoice', {
                get: ''
            }),
            advanced: { edit: 'advanced/:id_client/' }
        }),
        user: include('user', {
            get: '',
            edit: ':id_user/',
            options: 'options'
        }),
        invoice: include('invoice', {
            get: '',
            edit: ':id_invoice/',
            cancel_transaction: ':id_invoice/cancel-transaction',
            list: 'm/:month/y/:year',
            generate_invoices: 'generate-invoices',
            options: 'options/:id_invoice/'
        }),
        service: include('service', {
            get: '',
            edit: ':id_service/'
        }),
        settings: include('settings', {
            get: '',
            edit: ':id_setting/'
        }),
        database: include('database', {
            get: '',
            edit: ':id_database/'
        })
    })
};
