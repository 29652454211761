import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';

function DatatableTopSearch() {
    const searchParams = new URLSearchParams(window.location.search);
    const query = searchParams.get('q') || '';

    const sendQuery = (query, history) => {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('q', query);
        searchParams.set('page', '1');
        history.push(`?${searchParams.toString()}`);
    };
    const history = useHistory();

    return (
        <div className="kt-portlet__head-wrapper ml-1">
            <div id="toolbar" className="d-inline-flex mt-auto">
                <Formik
                    initialValues={{ q: query }}
                    onSubmit={(values) => {
                        sendQuery(values.q, history);
                    }}
                >
                    <Form id="changelist-search" method="get" className="d-flex">
                        <Field
                            type="text"
                            autoFocus
                            className="form-control"
                            size="20"
                            name="q"
                            id="searchbar"
                            placeholder="Buscar..."
                        />
                        <FontAwesomeIcon id="search-icon" icon={['fas', 'search']} />
                        <Field className="btn btn-primary" type="submit" value="Pesquisar" />
                    </Form>
                </Formik>
            </div>
        </div>
    );
}

export default DatatableTopSearch;
