export const status = {
    1: {
        label: 'Pendente Confirmação',
        color: 'primary'
    },
    2: {
        label: 'Pendente Envio',
        color: 'dark'
    },
    3: {
        label: 'Pendente Pagamento',
        color: 'warning'
    },
    4: {
        label: 'Pago',
        color: 'success'
    },
    5: {
        label: 'Cancelado',
        color: 'danger'
    }
};
