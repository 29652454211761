import { useHistory } from 'react-router-dom';
import React, { useContext } from 'react';
import { DatatableContext } from 'components/DataTables/DatatableContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function DatatableBottomPagination() {
    const { pagination } = useContext(DatatableContext);
    const { total, count } = pagination;
    const searchParams = new URLSearchParams(window.location.search);
    const history = useHistory();
    const currentPage = parseInt(searchParams.get('page') || 1);
    const next = currentPage + 1;
    const previous = currentPage - 1;
    const per_page = searchParams.get('per_page');
    function getPages() {
        let paginator = [
            previous - 2,
            previous - 1,
            previous,
            currentPage,
            next,
            next + 1,
            next + 2
        ];
        if (!next) {
            paginator = [previous - 2, previous - 1, previous, currentPage];
        }

        let paginatorPages = [];
        for (const page of paginator) {
            if (page >= 1 && page <= total) {
                paginatorPages.push(parseInt(page));
            }
        }
        paginatorPages = [...new Set(paginatorPages)];
        paginatorPages.sort((a, b) => a - b);
        return paginatorPages;
    }

    function setPage(e, page) {
        e.preventDefault();
        searchParams.set('page', page);
        history.push(`?${searchParams.toString()}`);
        window.scrollTo(0, 0);
    }

    function setPerPage(pp) {
        searchParams.set('per_page', pp);
        history.push(`?${searchParams.toString()}`);
        window.scrollTo(0, 0);
    }

    const pages = getPages();

    const hasPrevious = currentPage > 1;
    const hasNext = currentPage < total;

    return (
        <div className="kt-pagination ml-3">
            <ul className="kt-pagination__links">
                {pages.length > 1 && hasPrevious && (
                    <>
                        <li className="kt-pagination__link--prev">
                            <a
                                onClick={(e) => setPage(e, 1)}
                                href={'/'}
                                className={
                                    'kt-datatable__pager-link' +
                                    (hasPrevious || 'kt-datatable__pager-link--disabled')
                                }
                            >
                                <FontAwesomeIcon icon={'angle-double-left'} />
                            </a>
                        </li>
                        <li className="kt-pagination__link--prev">
                            <a onClick={(e) => setPage(e, previous)} href={'/'}>
                                <FontAwesomeIcon icon={'angle-left'} />
                            </a>
                        </li>
                    </>
                )}
                {pages.length > 1 &&
                    pages.map((page, i) => {
                        let activeClass = '';
                        if (currentPage === page) {
                            activeClass = 'kt-pagination__link--active';
                        }
                        return (
                            <li key={i} className={activeClass}>
                                <a
                                    data-page={page}
                                    title={page}
                                    onClick={(e) => setPage(e, page)}
                                    href={'/'}
                                >
                                    <span>{page}</span>
                                </a>
                            </li>
                        );
                    })}
                {pages.length > 1 && hasNext && (
                    <>
                        <li className="kt-pagination__link--next">
                            <a onClick={(e) => setPage(e, next)} href={'/'}>
                                <FontAwesomeIcon icon={'angle-right'} />
                            </a>
                        </li>
                        <li className="kt-pagination__link--next">
                            <a onClick={(e) => setPage(e, total)} href={'/'}>
                                <FontAwesomeIcon icon={'angle-double-right'} />
                            </a>
                        </li>
                    </>
                )}
            </ul>
            <div className="kt-pagination__toolbar">
                <select
                    value={per_page || '40'}
                    onChange={(e) => setPerPage(e.target.value)}
                    className="form-control"
                >
                    <option value="40">40</option>
                    <option value="80">80</option>
                    <option value="120">120</option>
                    <option value="200">200</option>
                </select>
                <span className="pagination__desc" style={{ width: '200px' }}>
                    Total: {count} Items
                </span>
            </div>
        </div>
    );
}
