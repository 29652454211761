import { ErrorMessage, useField, useFormikContext } from 'formik';
import FieldWrapper from 'components/Form/Fields/FieldWrapper';
import React from 'react';
import ErrorField from 'components/Form/Fields/ErrorField';
import HelpText from 'components/Form/Fields/HelpText';
import InputMask from 'react-input-mask';

export default function IntegerField({ name, help, ...props }) {
    const [field] = useField({ ...props, name });
    const { errors } = useFormikContext();
    field.value = !field.value ? '' : field.value;

    return (
        <FieldWrapper name={name} {...props}>
            <InputMask
                {...props}
                {...field}
                type="number"
                className={errors[name] ? 'form-control is-invalid' : 'form-control'}
            />
            {help && <HelpText>{help}</HelpText>}
            <ErrorMessage name={name} component={ErrorField} />
        </FieldWrapper>
    );
}
