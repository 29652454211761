import React, { useCallback, useContext, useEffect } from 'react';
import { Form, Formik } from 'formik';
import { BreadcrumbContext } from 'components/Breadcrumbs/BreadcrumbContext';
import TextField from 'components/Form/Fields/TextField';
import User from 'services/auth/user';
import { urls } from 'routes/app';
import { reverse } from 'named-urls';
import Panel from 'components/Form/Panel';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

export default function ChangePassword() {
    const { setBreadcrumbs, setTitle } = useContext(BreadcrumbContext);
    const history = useHistory();
    const { addToast } = useToasts();

    useEffect(() => {
        setTitle('Alterar Senha');
        setBreadcrumbs(breadcrumbs);
    }, []);

    const breadcrumbs = [
        {
            label: 'Alterar Senha',
            url: '#'
        }
    ];

    const changePassword = useCallback((values, actions) => {
        User.change_password(values)
            .then(() => {
                addToast('Senha alterada com sucesso!', {
                    appearance: 'success',
                    autoDismiss: true
                });
                history.push(reverse(urls.admin.index));
            })
            .catch((error) => {
                if (error.response) {
                    actions.setErrors(error.response.data.errors);
                }
            });
    }, []);

    return (
        <div className="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">
            <Formik initialValues={{}} onSubmit={changePassword} enableReinitialize={true}>
                <Form
                    id="form-crud"
                    className="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid"
                >
                    <Panel title="Alterar Senha">
                        <TextField name="password1" autoFocus label="Senha" type="password" />
                        <TextField name="password2" label="Repita a Senha" type="password" />
                    </Panel>
                    <button type="submit" className="btn-elevate btn btn-primary btn-pill">
                        Alterar Senha
                    </button>
                </Form>
            </Formik>
        </div>
    );
}
