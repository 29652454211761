import IntegerField from 'components/Form/Fields/IntegerField';
import ReadOnlyField from 'components/Form/Fields/ReadOnlyField';
import TextField from 'components/Form/Fields/TextField';
import Panel from 'components/Form/Panel';
import PkField from 'components/Utils/Form/PkField';
import ReadOnlyLabel from 'components/Utils/Form/ReadOnlyLabel';
import { useFormikContext } from 'formik';
import AdminFormLayout from 'layouts/admin/AdminFormLayout';
import { reverse } from 'named-urls/src';
import React from 'react';
import { useParams } from 'react-router-dom';
import { urls } from 'routes/app';
import Database from 'services/admin/database';

export default function FormDatabase() {
    const { id_database } = useParams();
    const title = id_database ? 'Editar Base de Dados' : 'Adicionar Base de Dados';

    const formUrls = {
        add: reverse(urls.admin.database.add),
        edit: reverse(urls.admin.database.edit, { id_database: id_database }),
        list: reverse(urls.admin.database.list),
        delete: reverse(urls.admin.database.delete, { id_database: id_database })
    };

    const breadcrumbs = [
        {
            label: 'Lista de Base de Dados',
            url: formUrls.list
        },
        {
            label: title,
            url: ''
        }
    ];

    return (
        <AdminFormLayout
            id={id_database}
            source={new Database(id_database)}
            initialValues={{
                is_active: true
            }}
            breadcrumbs={breadcrumbs}
            title={title}
            urls={formUrls}
        >
            <Form id_database={id_database} />
        </AdminFormLayout>
    );
}

function Form({ id_database }) {
    const { values } = useFormikContext();

    return (
        <Panel>
            <PkField />
            {!id_database && (
                <>
                    <TextField name="name" autoFocus={true} label="Nome" required={true} />
                    <IntegerField name="id_proseleta" label="ID Proseleta" />
                </>
            )}
            {!!id_database && (
                <>
                    <ReadOnlyField label="Banco">
                        <ReadOnlyLabel>
                            {values.id_proseleta} - {values.name}
                        </ReadOnlyLabel>
                    </ReadOnlyField>
                    <IntegerField name="id_proseleta" label="ID Proseleta" />
                </>
            )}
        </Panel>
    );
}
