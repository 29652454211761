import ReadOnlyField from 'components/Form/Fields/ReadOnlyField';
import ReadOnlyLabel from 'components/Utils/Form/ReadOnlyLabel';
import { useFormikContext } from 'formik';
import React from 'react';
import { urls } from 'routes/app';
import { reverse } from 'named-urls/src';
import { useParams } from 'react-router-dom';
import TextField from 'components/Form/Fields/TextField';
import Panel from 'components/Form/Panel';
import PkField from 'components/Utils/Form/PkField';
import AdminFormLayout from 'layouts/admin/AdminFormLayout';
import Settings from 'services/admin/settings';

export default function FormSettings() {
    const { id_setting } = useParams();
    const title = 'Editar Configuração';

    const formUrls = {
        edit: reverse(urls.admin.settings.edit, { id_setting: id_setting }),
        list: reverse(urls.admin.settings.list)
    };

    const breadcrumbs = [
        {
            label: 'Lista de Configurações',
            url: formUrls.list
        },
        {
            label: title,
            url: ''
        }
    ];

    return (
        <AdminFormLayout
            id={id_setting}
            source={new Settings(id_setting)}
            initialValues={{}}
            breadcrumbs={breadcrumbs}
            title={title}
            urls={formUrls}
        >
            <Form />
        </AdminFormLayout>
    );
}

function Form() {
    const { values } = useFormikContext();

    return (
        <Panel>
            <PkField />
            <ReadOnlyField label="Chave">
                <ReadOnlyLabel>
                    <span>{values.key}</span>
                </ReadOnlyLabel>
            </ReadOnlyField>
            <TextField name="value" label="Valor" required={true} />
        </Panel>
    );
}
