import ReadOnlyField from 'components/Form/Fields/ReadOnlyField';
import ReadOnlyLabel from 'components/Utils/Form/ReadOnlyLabel';
import { useFormikContext } from 'formik';
import React from 'react';

export default function PkField({ pk }) {
    const { values } = useFormikContext();
    let id = values[pk || 'id'];

    if (!id) {
        return <></>;
    }

    return (
        <ReadOnlyField label="ID">
            <ReadOnlyLabel>{String(id).padStart(6, '0')}</ReadOnlyLabel>
        </ReadOnlyField>
    );
}
