import React, { useContext } from 'react';
import AdminRouter from 'routes/routers/AdminRouter';
import AuthRouter from 'routes/routers/AuthRouter';
import { UserContext } from 'context/UserContext';
import { BrowserRouter, Redirect, Route, Switch, useHistory } from 'react-router-dom';
import Page404 from 'pages/error/Page404';
import ClientRouter from './ClientRouter';
import Logout from 'pages/admin/auth/logout';

export default function CoreRouter() {
    const history = useHistory();
    const { isLoaded } = useContext(UserContext);

    if (!isLoaded) {
        return <></>;
    }

    return (
        <BrowserRouter history={history}>
            <Switch>
                <Route exact={true} path="/auth/logout">
                    <Logout />
                </Route>
                <Route path="/auth">
                    <AuthRouter />
                </Route>
                <Route path="/client">
                    <ClientRouter />
                </Route>
                <Route path="/admin">
                    <AdminRouter />
                </Route>
                <Route exact={true} path="/">
                    <Redirect to={'/admin'} />
                </Route>
                <Route path="*">
                    <Page404 />
                </Route>
            </Switch>
        </BrowserRouter>
    );
}
