import { Route, Switch } from 'react-router-dom';
import React from 'react';
import Page404 from 'pages/error/Page404';

export default function AutoRouter(props) {
    return (
        <Switch>
            {props.routes.map((route, i) => (
                <RouteWithSubRoutes key={i} {...route} />
            ))}
            <Route path="*" render={Page404} />
        </Switch>
    );
}

function RouteWithSubRoutes(route) {
    return (
        <Route
            exact={route.exact || true}
            path={route.path}
            render={(props) => <route.component {...props} routes={route.routes} />}
        />
    );
}
