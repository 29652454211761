import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Email({ email }) {
    if (!email) {
        return '-';
    }

    return (
        <a className={'text-primary'} href={'mailto:' + email}>
            <FontAwesomeIcon className={'mr-1'} icon={'envelope'} /> {email}
        </a>
    );
}
