import { Field, Form, Formik } from 'formik';
import { setJWTData } from 'helpers/jwt';
import LoginLayout from 'layouts/auth/LoginLayout';
import React, { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import User from 'services/auth/user';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export default function Login() {
    const [errors, setErrors] = useState('');
    const [loading, setLoading] = useState(false);
    const { addToast } = useToasts();

    const login = (values, actions) => {
        setLoading(true);
        User.login(values)
            .then((data) => {
                const { access_token } = data;
                setJWTData(access_token);
                window.location.href = '/admin';
            })
            .catch((error) => {
                setLoading(false);
                if (error.response && error.response.status === 422) {
                    actions.setFieldValue('password', '');
                    setErrors(error.response.data.errors[0]);
                    addToast('Email ou senha inválidos...', {
                        appearance: 'error',
                        autoDismiss: true
                    });
                    return;
                }
                const MySwal = withReactContent(Swal);
                MySwal.fire({
                    title: 'Erro',
                    icon: 'warning',
                    iconColor: 'red',
                    text: 'Ops... Parece que houve um erro no servidor.'
                });
            });
    };

    return (
        <LoginLayout title={'Login'}>
            <Formik
                onSubmit={login}
                initialValues={{
                    email: '',
                    password: ''
                }}
            >
                <Form className="kt-login-v2__form kt-form">
                    {errors && (
                        <div>
                            <div className="alert alert-bold alert-solid-danger">
                                <div className="alert-text">{errors}</div>
                            </div>
                        </div>
                    )}
                    <div className="form-group mb-4">
                        <label>Email</label>
                        <Field
                            type="email"
                            autoFocus
                            style={{
                                fontSize: '15px',
                                fontWeight: '400'
                            }}
                            required={true}
                            className="form-control"
                            name="email"
                        />
                    </div>
                    <div className="form-group mb-4">
                        <label>Senha</label>
                        <Field
                            type="password"
                            style={{
                                fontSize: '15px',
                                fontWeight: '400'
                            }}
                            required={true}
                            className="form-control"
                            name="password"
                        />
                    </div>
                    <div className="mt-4">
                        <div className="kt-login-v2__actions d-block text-center">
                            <button type="submit" className="btn btn-brand btn-elevate btn-pill">
                                {loading && (
                                    <span
                                        className="spinner-border spinner-border-sm mr-2"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                )}
                                Login
                            </button>
                        </div>
                    </div>
                </Form>
            </Formik>
        </LoginLayout>
    );
}
