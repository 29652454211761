import { reverse } from 'named-urls';
import { urls } from 'routes/api';
import api from 'helpers/api';

export default class ClientDatabase {
    constructor(id_client = 0) {
        this.id_client = id_client;
    }

    async show() {
        let url = reverse(urls.admin.client.database.edit, { id_client: this.id_client });
        const { data } = await api.get(url);
        return data;
    }

    async update(values) {
        let url = reverse(urls.admin.client.database.edit, { id_client: this.id_client });
        const { data } = await api.put(url, values);
        return data;
    }

    async options(params) {
        let url = reverse(urls.admin.client.database.options);
        const { data } = await api.get(url, { params: params });
        return data;
    }

    async save(values) {
        return this.update(values);
    }
}
