export const configs = {
    add_button_label: 'Adicionar',
    base_breadcrumb: {
        icon: 'home',
        url: '/admin',
        label: 'Início'
    },
    default_actions: {
        delete: 'Deletar itens selecionados'
    },
    actions: {
        no_selection: 'Selecione uma ação.',
        apply: 'Aplicar',
        error: 'Ops... Corrija os campos indicados.',
        success: 'Tudo certo! A ação foi aplicada nos items selecionados.'
    },
    no_result: 'Nenhum resultado encontrado.',
    no_result_search: 'Nenhum resultado encontrado para a busca {q}.'
};
