import { reverse } from 'named-urls';
import { urls } from 'routes/api';
import api from 'helpers/api';

export default class User {
    static async show() {
        let url = reverse(urls.auth.user);
        const { data } = await api.get(url);
        return data;
    }

    static async login(values) {
        let url = reverse(urls.auth.login);
        delete api.defaults.headers.common['Authorization'];
        const { data } = await api.post(url, values);
        return data;
    }

    static async change_password(values) {
        let url = reverse(urls.admin.change_password);
        const { data } = await api.post(url, values);
        return data;
    }

    static async logout() {
        let url = reverse(urls.auth.logout);
        const { data } = await api.get(url);
        return data;
    }
}
