import { reverse } from 'named-urls';
import { urls } from 'routes/api';
import api from 'helpers/api';

export default class ClientInvoice {
    constructor(id_client = 0) {
        this.id_client = id_client || 0;
    }

    async list(params) {
        let url = reverse(urls.admin.client.invoice.get, { id_client: this.id_client });
        const { data } = await api.get(url, { params: params });
        return data;
    }
}
