import React, { useContext } from 'react';
import BreadcrumbItem from 'components/Breadcrumbs/BreadcrumbItem';
import { configs } from 'configs/list';
import { BreadcrumbContext } from 'components/Breadcrumbs/BreadcrumbContext';

function Breadcrumb() {
    const { breadcrumbs, title } = useContext(BreadcrumbContext);

    if (!breadcrumbs) {
        return <></>;
    }
    return (
        <div className="kt-subheader kt-grid__item" id="kt_subheader">
            <div className="kt-container kt-container--fluid">
                <div className="kt-subheader__main">
                    <h3 className="kt-subheader__title">{title}</h3>
                    <span className="kt-subheader__separator kt-hidden" />
                    <div className="kt-subheader__breadcrumbs" id="breadcrumbs">
                        <BreadcrumbItem
                            home={true}
                            icon={configs.base_breadcrumb.icon}
                            url={configs.base_breadcrumb.url}
                            label={configs.base_breadcrumb.label}
                        />
                        {breadcrumbs.map((data, i) => (
                            <BreadcrumbItem key={i} url={data.url} label={data.label} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Breadcrumb;
