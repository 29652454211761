import React from 'react';

function DatatableTop(props) {
    return (
        <div className="kt-portlet__head-toolbar d-flex kt-space-between mt-3 mb-3">
            {props.children}
        </div>
    );
}

export default DatatableTop;
