import React from 'react';
import DatatableTopSearch from 'components/DataTables/Top/DatatableTopSearch';
import DatatableTopButtons from 'components/DataTables/Top/DatatableTopButtons';

function DatatableTopTools() {
    return (
        <div className="tools float-right d-flex">
            <DatatableTopSearch />
            <DatatableTopButtons />
        </div>
    );
}

export default DatatableTopTools;
