import React from 'react';
import { FieldArray } from 'formik';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReadOnlyField from 'components/Form/Fields/ReadOnlyField';
import SwitchField from 'components/Form/Fields/SwitchField';

function RowRenderer({ row, pk, index, canDelete, name, remove, columns }) {
    let id = row[pk || 'id'] || false;

    return (
        <tr>
            <th className="field-datatable">
                <span>{id || '-'}</span>
            </th>
            {columns.map((data, i) => (
                <th className="field-datatable" key={i} style={data.style || {}}>
                    {data.render(row, index)}
                </th>
            ))}
            {canDelete && id && (
                <th className="field-datatable">
                    <SwitchField className={'mt-1'} size="sm" name={`${name}.${index}.destroy`} />
                </th>
            )}
            {canDelete && !id && (
                <th>
                    <ReadOnlyField>
                        <Button onClick={() => remove(index)} className={'btn btn-sm btn-danger'}>
                            <FontAwesomeIcon icon={'trash'} />
                        </Button>
                    </ReadOnlyField>
                </th>
            )}
        </tr>
    );
}

export default function TabularInline({ columns, canDelete = true, name, pk, options, newValues }) {
    return (
        <FieldArray name={name}>
            {({ insert, remove, push }) => (
                <>
                    <table className="table kt-datatable__table table-striped table-hover">
                        <thead className="kt-datatable__head">
                            <tr className="kt-datatable__row">
                                <th scope="col" className="datatable-col-id">
                                    <div className="text d-inline-flex">ID</div>
                                </th>
                                {columns &&
                                    columns.map((data, i) => {
                                        return (
                                            <th key={i} scope="col">
                                                <div className="text d-inline-flex">
                                                    {data.label}
                                                </div>
                                            </th>
                                        );
                                    })}
                                {canDelete && (
                                    <th scope="col" className="datatable-col-sm">
                                        <div className="text d-inline-flex">Deletar?</div>
                                    </th>
                                )}
                            </tr>
                        </thead>
                        <tbody className="kt-datatable__body">
                            {options &&
                                options.map((data, i) => (
                                    <RowRenderer
                                        canDelete={canDelete}
                                        name={name}
                                        row={data}
                                        pk={pk}
                                        remove={remove}
                                        columns={columns}
                                        index={i}
                                        key={i}
                                    />
                                ))}
                        </tbody>
                    </table>
                    {newValues && (
                        <Button onClick={() => push(newValues)} className="mt-4">
                            <FontAwesomeIcon className={'mr-2'} icon={'plus'} />
                            Adicionar
                        </Button>
                    )}
                </>
            )}
        </FieldArray>
    );
}
