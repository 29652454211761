import React, { useContext } from 'react';
import DatatableTopFilterItem from 'components/DataTables/Top/DatatableTopFilterItem';
import { DatatableContext } from 'components/DataTables/DatatableContext';

function DatatableTopFilters() {
    const { filters } = useContext(DatatableContext);

    if (!filters) {
        return <></>;
    }

    return (
        <div className="filters float-left d-flex mt-auto">
            <span className="kt-font-bold mr-3 ml-2 pt-2">Filtros:</span>
            {filters.map((data, i) => {
                return <DatatableTopFilterItem key={i} {...data} />;
            })}
        </div>
    );
}

export default DatatableTopFilters;
