import AutoRouter from 'components/Router/AutoRouter';
import React, { useContext } from 'react';
import { paths } from 'routes/paths/admin';
import { UserContext } from 'context/UserContext';
import { Redirect } from 'react-router-dom';
import { reverse } from 'named-urls';
import { urls } from 'routes/app';
import DashboardBody from 'components/Dashboard/DashboardBody';
import AdminHeader from 'layouts/admin/AdminHeader';
import DashboardContainer from 'components/Dashboard/DashboardContainer';
import DashboardContainerFluid from 'components/Dashboard/DashboardContainerFluid';
import { BreadcrumbContextProvider } from 'components/Breadcrumbs/BreadcrumbContext';
import Breadcrumb from 'components/Breadcrumbs/Breadcrumb';

export default function AdminRouter() {
    const { isAuthenticated } = useContext(UserContext);

    if (!isAuthenticated) {
        return <Redirect to={reverse(urls.auth.login)} />;
    }

    return (
        <BreadcrumbContextProvider>
            <DashboardBody>
                <AdminHeader />
                <DashboardContainer>
                    <Breadcrumb />
                    <DashboardContainerFluid>
                        <AutoRouter routes={paths} />
                    </DashboardContainerFluid>
                </DashboardContainer>
            </DashboardBody>
        </BreadcrumbContextProvider>
    );
}
