import DateField from 'components/Form/Fields/DateField';
import IntegerField from 'components/Form/Fields/IntegerField';
import MoneyField from 'components/Form/Fields/MoneyField';
import ReadOnlyField from 'components/Form/Fields/ReadOnlyField';
import SelectField from 'components/Form/Fields/SelectField';
import TextAreaField from 'components/Form/Fields/TextAreaField';
import Panel from 'components/Form/Panel';
import AlertIcon from 'components/Utils/Alert/AlertIcon';
import Badge from 'components/Utils/DataTable/Badge';
import UserCard from 'components/Utils/DataTable/UserCard';
import PkField from 'components/Utils/Form/PkField';
import ReadOnlyLabel from 'components/Utils/Form/ReadOnlyLabel';
import TabularInline from 'components/Utils/Form/TabularInline';
import Icon from 'components/Utils/Icon';
import { configs } from 'configs/form';
import { useFormikContext } from 'formik';
import { money } from 'helpers/format';
import AdminFormLayout from 'layouts/admin/AdminFormLayout';
import moment from 'moment';
import { reverse } from 'named-urls/src';
import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { urls } from 'routes/app';
import Invoice from 'services/admin/invoice';
import ClientInvoice from 'services/admin/invoice';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export default function FormInvoice() {
    const { month, year, id_invoice } = useParams();
    const title = id_invoice ? 'Editar Fatura' : 'Adicionar Fatura';

    const formUrls = {
        add: reverse(urls.admin.invoice.add, { month: month, year: year }),
        edit: reverse(urls.admin.invoice.edit, {
            month: month,
            year: year,
            id_invoice: id_invoice
        }),
        list: reverse(urls.admin.invoice.list, { month: month, year: year }),
        delete: reverse(urls.admin.invoice.delete, {
            month: month,
            year: year,
            id_invoice: id_invoice
        })
    };

    const breadcrumbs = [
        {
            label: 'Lista de Faturas',
            url: formUrls.list
        },
        {
            label: title,
            url: ''
        }
    ];

    const [options, setOptions] = useState({});

    return (
        <AdminFormLayout
            id={id_invoice}
            source={new ClientInvoice(id_invoice)}
            setOptions={setOptions}
            validation={{}}
            initialValues={{}}
            breadcrumbs={breadcrumbs}
            title={title}
            urls={formUrls}
        >
            <Form options={options} />
        </AdminFormLayout>
    );
}

function Form({ options }) {
    const { values } = useFormikContext();
    let transactions = values.transactions || [];

    let total = 0;
    if (values.items) {
        for (let item of values.items) {
            total = total + item.value * item.quantity;
        }
    }

    let readOnly = parseInt(values.id_status) === 4;
    for (let i of transactions) {
        if (i.status === 'pending') {
            readOnly = true;
        }
    }

    return (
        <>
            {!values.id && <AlertIcon>Salve a fatura para edição detalhada.</AlertIcon>}
            <InvoicePanel values={values} options={options} readOnly={readOnly} />
            <ItemsPanel readOnly={readOnly} options={options} items={values.items} total={total} />
            <TransactionPanel transactions={values.transactions} readOnly={readOnly} />
            <NotesPanel notes={values.notes} />
        </>
    );
}

function InvoicePanel({ values, options, readOnly }) {
    const submission_date = moment(values.submission_date, 'YYYY-MM-DD').format('DD/MM/YYYY');
    const expiration_date = moment(values.expiration_date, 'YYYY-MM-DD').format('DD/MM/YYYY');

    if (readOnly) {
        return (
            <>
                <AlertIcon>
                    Você não poderá alterar os items desta fatura. Ela já possui boleto / pix
                    gerados.
                </AlertIcon>
                <Panel title="Informações do Cliente">
                    <ReadOnlyField label="Cliente">
                        <ReadOnlyLabel>{values.client.name}</ReadOnlyLabel>
                    </ReadOnlyField>
                    <ReadOnlyField label="Razão Social">
                        <ReadOnlyLabel>{values.client.company_name}</ReadOnlyLabel>
                    </ReadOnlyField>
                    <ReadOnlyField label="CNPJ">
                        <ReadOnlyLabel>{values.client.cnpj}</ReadOnlyLabel>
                    </ReadOnlyField>
                    <ReadOnlyField label="Email">
                        <ReadOnlyLabel>{values.client.email || '-'}</ReadOnlyLabel>
                    </ReadOnlyField>
                </Panel>
                <Panel title="Informações da Fatura">
                    <SelectField
                        name="id_status"
                        required={true}
                        options={options.id_status || []}
                        label="Status"
                    />
                    <MoneyField
                        label="Total Pago"
                        name="total_paid"
                        size="sm"
                        help={
                            values.expired ? (
                                <span className="ml-2 mt-2 badge-danger badge">Em Atraso</span>
                            ) : (
                                ''
                            )
                        }
                        style={{ width: '5rem' }}
                    />
                    {values.id > 0 && values.id_status > 1 && (
                        <ReadOnlyField label="Link da Fatura">
                            <ReadOnlyLabel>
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={reverse(urls.client.invoice, { uuid: values.uuid })}
                                >
                                    Acessar
                                </a>
                            </ReadOnlyLabel>
                        </ReadOnlyField>
                    )}
                    <ReadOnlyField label="Data de Envio">
                        <ReadOnlyLabel>{submission_date}</ReadOnlyLabel>
                    </ReadOnlyField>
                    <ReadOnlyField label="Data de Vencimento">
                        <ReadOnlyLabel>{expiration_date}</ReadOnlyLabel>
                    </ReadOnlyField>
                </Panel>
            </>
        );
    }

    return (
        <Panel title="Informações Gerais">
            <PkField />
            {values.id && (
                <>
                    <ReadOnlyField label="Cliente">
                        <ReadOnlyLabel>
                            <Link
                                to={reverse(urls.admin.client.edit, {
                                    id_client: values.client.id
                                })}
                            >
                                {values.client.name} - CNPJ: {values.client.cnpj}
                            </Link>
                        </ReadOnlyLabel>
                    </ReadOnlyField>
                    <SelectField
                        name="id_status"
                        required={true}
                        options={options.id_status || []}
                        label="Status"
                    />
                    <div>
                        <MoneyField
                            label="Total Pago"
                            name="total_paid"
                            size="sm"
                            help={
                                values.expired ? (
                                    <span className="ml-2 mt-2 badge-danger badge">Em Atraso</span>
                                ) : (
                                    ''
                                )
                            }
                            style={{ width: '5rem' }}
                        />
                    </div>
                </>
            )}
            {!values.id && (
                <SelectField
                    name="id_client"
                    required={true}
                    label="Cliente"
                    options={options.id_client || []}
                />
            )}
            {values.id > 0 && values.id_status > 1 && (
                <ReadOnlyField label="Link da Fatura">
                    <ReadOnlyLabel>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={reverse(urls.client.invoice, { uuid: values.uuid })}
                        >
                            Acessar
                        </a>
                    </ReadOnlyLabel>
                </ReadOnlyField>
            )}
            <DateField
                label="Data de Envio da Fatura"
                required={true}
                autoFocus
                size="sm"
                help={
                    'Caso a fatura tenha status "Confirmado", o email será enviado após este data.'
                }
                name="submission_date"
            />
            <DateField
                label="Data de Vencimento da Fatura"
                size="sm"
                required={true}
                name="expiration_date"
            />
        </Panel>
    );
}

function ItemsPanel({ options, items, total, readOnly }) {
    const { id_invoice } = useParams();

    if (!id_invoice) {
        return <></>;
    }

    if (readOnly) {
        return (
            <Panel title="Itens">
                <table className="table-striped table">
                    <thead className="bg-primary text-white">
                        <tr>
                            <th>Serviço</th>
                            <th>Descrição</th>
                            <th>Valor</th>
                            <th>Quantidade</th>
                            <th>ID Ref.</th>
                        </tr>
                    </thead>
                    <tbody className="kt-font-medium">
                        {items.map((data, i) => (
                            <tr key={i}>
                                <td className="align-middle">
                                    <span className="kt-font-bold">{data.service}</span>
                                </td>
                                <td className="align-middle">
                                    <span className="kt-font-bold">{data.description}</span>
                                </td>
                                <td className="align-middle kt-font-medium">
                                    <span>{money(data.value)}</span>
                                </td>
                                <td className="align-middle kt-font-medium">
                                    <span>{data.quantity}</span>
                                </td>
                                <td className="align-middle kt-font-medium">
                                    <span>{data.ref_id}</span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <h2>
                    Total: <span className="text-primary">{money(total)}</span>
                </h2>
            </Panel>
        );
    }

    return (
        <Panel title="Itens da Fatura" disabled={readOnly}>
            <TabularInline
                name={'items'}
                pk={'id'}
                columns={[
                    {
                        label: 'Tipo',
                        style: { width: '100px' },
                        render: (data, i) => {
                            if (data['id']) {
                                return data['service'];
                            }
                            return (
                                <SelectField
                                    options={options.id_service || []}
                                    name={`items.${i}.id_service`}
                                />
                            );
                        }
                    },
                    {
                        style: { width: '100px' },
                        label: 'Base',
                        render: (data, i) => {
                            if (data['id'] && data['database']) {
                                return data['database']['title'];
                            }
                            return (
                                <SelectField
                                    options={options.id_database || []}
                                    name={`items.${i}.id_database`}
                                />
                            );
                        }
                    },
                    {
                        label: 'ID - Referência',
                        style: { width: '100px' },
                        render: (data, i) => {
                            if (data['id'] && !data['has_ref_id']) {
                                return 'N/A';
                            }
                            return <IntegerField name={`items.${i}.ref_id`} />;
                        }
                    },
                    {
                        label: 'Descrição',
                        render: (data, i) => {
                            return (
                                <>
                                    <TextAreaField name={`items.${i}.description`} />
                                </>
                            );
                        }
                    },
                    {
                        style: { width: '150px' },
                        label: 'Valor',
                        render: (data, i) => {
                            return <IntegerField step="0.010" name={`items.${i}.value`} />;
                        }
                    },
                    {
                        style: { width: '150px' },
                        label: 'Quantidade',
                        render: (data, i) => {
                            return <IntegerField name={`items.${i}.quantity`} />;
                        }
                    },
                    {
                        style: { width: '100px' },
                        label: 'Total',
                        render: (data, i) => {
                            let value = money(data['value'] * data['quantity'], 'BRL');
                            return <ReadOnlyLabel>{value}</ReadOnlyLabel>;
                        }
                    }
                ]}
                newValues={{}}
                options={items}
            />
            <ReadOnlyField label="Total">
                <h3 className={'kt-font-bold text-primary mt-2'}>{money(total)}</h3>
            </ReadOnlyField>
        </Panel>
    );
}

function NotesPanel({ notes = [] }) {
    if (notes.length === 0) {
        return <></>;
    }

    return (
        <Panel title="Notas">
            <table className="table-striped table">
                <thead className="bg-primary text-white">
                    <tr>
                        <th>Criador</th>
                        <th>Nota</th>
                    </tr>
                </thead>
                <tbody className="kt-font-medium">
                    {notes.map((data, i) => (
                        <tr key={i}>
                            <td className="align-middle">
                                <span className="kt-font-bold">
                                    <UserCard
                                        avatar={''}
                                        title="Sistema"
                                        subtitle="Editado pelo Sistema"
                                    />
                                </span>
                            </td>
                            <td className="align-middle">
                                <span>{String(data.note).toUpperCase()}</span>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </Panel>
    );
}

function TransactionPanel({ transactions = [], readOnly }) {
    const { id_invoice } = useParams();

    if (transactions.length === 0) {
        return <></>;
    }

    const StatusBadge = ({ status }) => {
        let availableStatus = {
            paid: { label: 'Pago', color: 'success' },
            pending: { label: 'Pendente', color: 'primary' },
            cancelled: { label: 'Cancelado', color: 'danger' }
        };
        status = availableStatus[status];
        return <Badge {...status} />;
    };

    const cancelarTransactions = () => {
        const swal = withReactContent(Swal);
        swal.fire({
            icon: 'warning',
            iconColor: 'red',
            title: 'Aviso',
            text: 'Deseja cancelar os boletos pendentes? Isso não poderá ser desfeito!',
            showCancelButton: true,
            focusCancel: true,
            focusConfirm: false,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: configs.components.submit_row.delete_confirm,
            cancelButtonText: configs.components.submit_row.delete_cancel
        }).then(({ isConfirmed }) => {
            if (isConfirmed) {
                new Invoice(id_invoice)
                    .cancelTransaction()
                    .then(() => {
                        location.reload();
                    })
                    .catch((e) => {
                        console.log(e);
                        alert('Ocorreu um erro ao cancelar a fatura.');
                    });
            }
        });
    };

    return (
        <Panel title="Boletos / Pix Gerados">
            <Table responsive hover size="sm" borderless className="text-center">
                <thead className="bg-primary text-white">
                    <tr>
                        <th>ID</th>
                        <th>Código</th>
                        <th>Tipo</th>
                        <th>Arquivo</th>
                        <th>Status</th>
                        <th>Valor</th>
                        <th>Criado Em</th>
                        <th>Expira Em</th>
                    </tr>
                </thead>
                <tbody>
                    {transactions.map((item, i) => (
                        <tr key={i}>
                            <td>
                                <span className="kt-font-bold text-primary">{item.id}</span>
                            </td>
                            <td>{item.code}</td>
                            <td>
                                <b>{String(item.type).toUpperCase()}</b>
                            </td>
                            <td>
                                {item.path ? (
                                    <a
                                        rel="noreferrer"
                                        target="_blank"
                                        href={item.path}
                                        className="kt-font-bold text-primary"
                                    >
                                        Acessar Arquivo
                                    </a>
                                ) : (
                                    '-'
                                )}
                            </td>
                            <td>
                                <StatusBadge status={item.status} />
                            </td>
                            <td>{money(parseFloat(item.value || 0))}</td>
                            <td>{item.created_at}</td>
                            <td>{item.expire_at}</td>
                        </tr>
                    ))}
                    {readOnly && (
                        <tr>
                            <td colSpan={8}>
                                <button
                                    type="button"
                                    onClick={cancelarTransactions}
                                    className="btn btn-outline-danger text-center"
                                >
                                    <Icon icon="trash" /> Cancelar Boletos/Pix em Aberto
                                </button>
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </Panel>
    );
}
