import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

export default function Actions({ icon, options, label }) {
    return (
        <div className="dropdown">
            <a
                href="#"
                className="btn btn-sm text-dark     btn-clean btn-icon btn-icon-md"
                data-toggle="dropdown"
                aria-expanded="false"
            >
                <FontAwesomeIcon className={'mr-2'} icon={icon || 'ellipsis-v'} />
                {label && <span>{label}</span>}
            </a>
            <div className="dropdown-menu dropdown-menu-right" x-placement="bottom-end">
                <ul className="kt-nav">
                    {options &&
                        options.map((data, i) => {
                            return (
                                <li key={i} className="kt-nav__item">
                                    <Link to={data.url} className="kt-nav__link">
                                        <span className="kt-nav__link-text">
                                            <FontAwesomeIcon
                                                className={'mr-2'}
                                                icon={data.icon || 'circle'}
                                            />
                                            <span>{data.label}</span>
                                        </span>
                                    </Link>
                                </li>
                            );
                        })}
                </ul>
            </div>
        </div>
    );
}
