import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function AlertIcon({ icon = 'info-circle', color = 'info', children }) {
    return (
        <div className="alert alert-elevate alert-light" role="alert">
            <div className="alert-icon">
                <FontAwesomeIcon
                    style={{ fontSize: '2.4rem' }}
                    icon={icon}
                    className={'text-' + color}
                />
            </div>
            <div className="alert-text kt-font-bold">{children}</div>
        </div>
    );
}
