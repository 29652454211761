import { BreadcrumbContext } from 'components/Breadcrumbs/BreadcrumbContext';
import Panel from 'components/Form/Panel';
import LineChart from 'components/Utils/Dashboard/LineChart';
import LoadingSpinner from 'components/Utils/Spinner/LoadingSpinner';
import { money } from 'helpers/format';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Dashboard from 'services/admin/dashboard';

export default function AdminDashboard() {
    const [isLoaded, setLoaded] = useState(false);
    const [dataInscricoes, setDataInscricoes] = useState([]);
    const [dataTotal, setTotal] = useState([]);
    const { setTitle, setBreadcrumbs } = useContext(BreadcrumbContext);

    useEffect(() => {
        setTitle('Dashboard');
        setBreadcrumbs([]);
        new Dashboard()
            .getData()
            .then((response) => {
                const { invoices_items, total } = response.data;
                setDataInscricoes(invoices_items);
                setTotal(total);
            })
            .finally(() => {
                setLoaded(true);
            });
    }, []);

    if (!isLoaded) {
        return <LoadingSpinner />;
    }

    let total = dataTotal[0].data.slice(0).reverse();
    let totalPago = dataTotal[1].data.slice(0).reverse();

    let totalInscricoes = dataInscricoes[0].data.slice(0).reverse();
    let totalInscricoesPagas = dataInscricoes[1].data.slice(0).reverse();

    return (
        <div>
            <Row>
                <Col md={12} lg={12} xl={12}>
                    <Panel title={'Valor Total - Últimos 12 Meses'}>
                        <div className="d-flex flex-row">
                            <div style={{ height: 400, flex: 1, marginRight: '1rem' }}>
                                <LineChart theme="set1" data={dataTotal} />
                            </div>
                            <div style={{ flex: 1, maxWidth: '30rem' }}>
                                <table className="table text-center table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Período</th>
                                            <th scope="col">Total</th>
                                            <th scope="col">Total Pago</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {total.map((data, i) => (
                                            <tr key={i} className="kt-font-bold">
                                                <th scope="row">{i + 1}</th>
                                                <td>{data.label}</td>
                                                <td className="text-danger">{money(data.y)}</td>
                                                <td className="text-primary">
                                                    {money(totalPago[i].y)}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Panel>
                </Col>
                <Col md={12} lg={12} xl={12}>
                    <Panel title={'Inscrições - Últimos 12 Meses'}>
                        <div className="d-flex flex-row">
                            <div style={{ height: 400, flex: 1, marginRight: '1rem' }}>
                                <LineChart data={dataInscricoes} />
                            </div>
                            <div style={{ flex: 1, maxWidth: '30rem' }}>
                                <table className="table text-center able-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Mês</th>
                                            <th scope="col">Total</th>
                                            <th scope="col">Total Pago</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {totalInscricoes.map((data, i) => (
                                            <tr key={i} className="kt-font-bold">
                                                <th scope="row">{i + 1}</th>
                                                <td>{data.label}</td>
                                                <td className="text-danger">{parseInt(data.y)}</td>
                                                <td className="text-primary">
                                                    {parseInt(totalInscricoesPagas[i].y)}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Panel>
                </Col>
            </Row>
        </div>
    );
}
