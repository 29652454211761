import React from 'react';
import { urls } from 'routes/app';
import { reverse } from 'named-urls/src';
import Service from 'services/admin/service';
import AdminListLayout from 'layouts/admin/AdminListLayout';
import TableLink from 'components/DataTables/TableLink';

export default function ServiceList() {
    const title = 'Lista de Serviços';
    const add_url = reverse(urls.admin.service.add);
    const breadcrumbs = [
        {
            label: title,
            url: ''
        }
    ];
    const columns = [
        {
            size: 'sm',
            field: 'id',
            label: 'ID',
            render: (row) => (
                <TableLink to={reverse(urls.admin.service.edit, { id_service: row.id })}>
                    {String(row.id).padStart(6, '0')}
                </TableLink>
            )
        },
        {
            field: 'name',
            label: 'Nome'
        }
    ];

    return (
        <AdminListLayout
            source={new Service()}
            columns={columns}
            breadcrumbs={breadcrumbs}
            title={title}
            addUrl={add_url}
        />
    );
}
