import { reverse } from 'named-urls';
import { urls } from 'routes/api';
import api from 'helpers/api';

export default class Invoice {
    constructor(id_invoice = 0, month = 0, year = 0) {
        this.month = month;
        this.year = year;
        this.id_invoice = id_invoice || 0;
    }

    async options() {
        let url = reverse(urls.admin.invoice.options, { id_invoice: this.id_invoice });
        const { data } = await api.get(url);
        return data;
    }

    async create(values) {
        let url = reverse(urls.admin.invoice.get);
        const { data } = await api.post(url, values);
        return data;
    }

    async save(values) {
        if (this.id_invoice > 0) {
            return this.update(values);
        }
        return this.create(values);
    }

    async list(params) {
        let url = reverse(urls.admin.invoice.get);
        if (this.month > 0 && this.year > 0) {
            url = reverse(urls.admin.invoice.list, { month: this.month, year: this.year });
        }
        const { data } = await api.get(url, { params: params });
        return data;
    }

    async show() {
        let url = reverse(urls.admin.invoice.edit, { id_invoice: this.id_invoice });
        const { data } = await api.get(url);
        return data;
    }

    async delete() {
        let url = reverse(urls.admin.invoice.edit, { id_invoice: this.id_invoice });
        const { data } = await api.delete(url);
        return data;
    }

    async deleteMany(ids) {
        let url = reverse(urls.admin.invoice.get);
        const { data } = await api.delete(url, { params: { ids: ids } });
        return data;
    }

    async update(values) {
        let url = reverse(urls.admin.invoice.edit, { id_invoice: this.id_invoice });
        const { data } = await api.put(url, values);
        return data;
    }

    async cancelTransaction() {
        let url = reverse(urls.admin.invoice.cancel_transaction, { id_invoice: this.id_invoice });
        const { data } = await api.post(url);
        return data;
    }

    async generateInvoices(month, year) {
        let url = reverse(urls.admin.invoice.generate_invoices);
        const { data } = await api.post(url, {
            month: month,
            year: year
        });
        return data;
    }
}
