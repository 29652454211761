import { ErrorMessage, useField, useFormikContext } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import FieldWrapper from 'components/Form/Fields/FieldWrapper';
import ErrorField from 'components/Form/Fields/ErrorField';
import InputMask from 'react-input-mask';
import HelpText from 'components/Form/Fields/HelpText';

export default function IconField({ append, prepend, name, type, help, ...props }) {
    const [field] = useField({ ...props, name });
    const { errors } = useFormikContext();
    field.value = field.value === undefined ? '' : field.value;

    return (
        <FieldWrapper {...props}>
            <div className="input-group">
                {prepend && (
                    <div className="input-group-prepend">
                        <span className="input-group-text">
                            <FontAwesomeIcon icon={prepend} />
                        </span>
                    </div>
                )}
                <InputMask
                    {...field}
                    autoComplete="new-password"
                    type={type || 'text'}
                    className={errors[name] ? 'form-control is-invalid' : 'form-control'}
                    {...props}
                />
                {append && (
                    <div className="input-group-append">
                        <span className="input-group-text">
                            <FontAwesomeIcon icon={append} />
                        </span>
                    </div>
                )}
                <ErrorMessage name={name} component={ErrorField} />
            </div>
            {help && <HelpText>{help}</HelpText>}
        </FieldWrapper>
    );
}
