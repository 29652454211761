import React, { useContext, useRef } from 'react';
import DatatableRowCheckbox from 'components/DataTables/Row/DatatableRowCheckbox';
import { DatatableContext } from 'components/DataTables/DatatableContext';

export default function DatatableRow({ data }) {
    let { primaryKey, columns, showCheckbox, ids } = useContext(DatatableContext);
    let currentId = data[primaryKey] || 0;
    let isSelected = ids.ids.includes(currentId);

    const clicarCheckBox = (id) => {
        let elemento = document.querySelector(`[data-datatable-checkbox="${id}"]`);
        if (elemento) {
            elemento.click();
        }
    };

    const abrirLink = (e) => {
        let links = trRef.current.getElementsByTagName('a');
        if (links.length > 0) {
            links[0].click();
        }
    };

    let trRef = useRef();
    let selectedClass = isSelected ? 'selected' : '';

    return (
        <tr
            ref={trRef}
            onDoubleClick={(e) => abrirLink(e)}
            className={'kt-datatable__cell ' + selectedClass}
        >
            {showCheckbox && <DatatableRowCheckbox id={currentId} />}
            {columns.map((column, i) => {
                return (
                    <th
                        onClick={(e) => clicarCheckBox(currentId)}
                        key={i}
                        className={'field-datatable field-' + column.field}
                        style={{ fontWeight: 500 }}
                    >
                        <RowRenderer column={column} row={data} />
                    </th>
                );
            })}
        </tr>
    );
}

function RowRenderer({ column, row }) {
    if (column.render) {
        return column.render(row);
    }
    return row[column.field] || '-';
}
