import React from 'react';

export default function Page404() {
    return (
        <div
            style={{
                backgroundColor: '#161c25',
                height: '100vh'
            }}
        >
            <div className="kt-grid kt-grid--ver kt-grid--root d-contents">
                <div className="kt-error404-v2">
                    <div className="kt-error404-v2__content">
                        <div className="kt-error404-v2__title">404</div>
                        <div className="kt-error404-v2__desc">
                            <strong className={'mr-1'}>OOPS!</strong>
                            Algo não está correto aqui...
                        </div>
                        <img
                            className={'pt-5 mt-5'}
                            style={{ maxHeight: '360px' }}
                            src={'/img/404/img.svg'}
                            alt={'background'}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
