import axios from 'axios';
import { getJWTData } from 'helpers/jwt';

const jwtData = getJWTData() || { token: '' };

const api = axios.create({
    crossdomain: true,
    headers: {
        accept: 'application/json',
        common: {
            Authorization: `Bearer ${jwtData.token}`
        },
        'Content-Type': 'application/json'
    },
    baseURL: process.env.REACT_APP_API_URL
});

export default api;
