import React from 'react';

export default function UserCard({ url, title, subtitle, avatar = null }) {
    return (
        <div className="kt-user-card-v2">
            {avatar && (
                <a href={url}>
                    <div className="kt-user-card-v2__pic">
                        <img src={avatar} alt="avatar" />
                    </div>
                </a>
            )}
            <div className="kt-user-card-v2__details">
                <div className="kt-user-card-v2__name">{title}</div>
                <span className="kt-user-card-v2__desc">{subtitle}</span>
            </div>
        </div>
    );
}
