import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useClick } from 'helpers/click';

function HeaderMenuItem(props) {
    const [ref, classMenu, setClassMenu] = useClick('');

    // Ignore menus with all children has no permission
    if (props.items) {
        let hasPerm = false;
        for (let i of props.items) {
            if (i.has_perm) {
                hasPerm = true;
                break;
            }
        }
        if (!hasPerm) {
            return '';
        }
    }

    if (props.items) {
        return (
            <div
                className={'kt-menu__item  kt-menu__item--submenu kt-menu__item--rel ' + classMenu}
                data-ktmenu-submenu-toggle="click"
            >
                <a
                    ref={ref}
                    onClick={() => {
                        if (classMenu === '') {
                            setClassMenu('kt-menu__item--open-dropdown kt-menu__item--hover');
                        } else {
                            setClassMenu('');
                        }
                    }}
                    className="kt-menu__link kt-menu__toggle"
                >
                    <FontAwesomeIcon className="kt-menu__link-icon" icon={props.icon} />
                    <span className="kt-menu__link-text">{props.label}</span>
                    <FontAwesomeIcon
                        className="kt-menu__hor-arrow"
                        icon="angle-down"
                        style={{
                            marginTop: '5px'
                        }}
                    />
                </a>

                <div className="kt-menu__submenu kt-menu__submenu--classic kt-menu__submenu--left">
                    <ul className="kt-menu__subnav">
                        {props.items.map((data, i) => {
                            if (!data.has_perm) {
                                return '';
                            }

                            return (
                                <li
                                    key={i}
                                    className="kt-menu__item  kt-menu__item--submenu"
                                    data-ktmenu-submenu-toggle="hover"
                                >
                                    <Link to={data.url || '#'} className="kt-menu__link">
                                        <FontAwesomeIcon
                                            className="kt-menu__link-icon"
                                            icon={data.icon}
                                        />
                                        <span className="kt-menu__link-text">{data.label}</span>
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        );
    }

    if (!props.has_perm) {
        return '';
    }

    return (
        <li
            key={0}
            className="kt-menu__item  kt-menu__item--submenu kt-menu__item--rel"
            data-ktmenu-submenu-toggle="click"
            aria-haspopup="true"
        >
            <Link className="kt-menu__link " to={props.url || '#'}>
                <FontAwesomeIcon className="kt-menu__link-icon" icon={props.icon} />
                <span className="kt-menu__link-text">{props.label}</span>
            </Link>
        </li>
    );
}

export default HeaderMenuItem;
