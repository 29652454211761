import ChangePassword from 'pages/admin/auth/change_password';
import FormClientDatabase from 'pages/admin/client/database';
import FormClient from 'pages/admin/client/form';
import ClientInvoiceList from 'pages/admin/client/invoice/list';
import ClientList from 'pages/admin/client/list';
import AdminDashboard from 'pages/admin/dashboard';
import FormDatabase from 'pages/admin/database/form';
import DatabaseList from 'pages/admin/database/list';
import FormInvoice from 'pages/admin/invoice/form';
import InvoiceList from 'pages/admin/invoice/list';
import InvoiceListAll from 'pages/admin/invoice/list_all';
import FormService from 'pages/admin/service/form';
import ServiceList from 'pages/admin/service/list';
import FormSettings from 'pages/admin/settings/form';
import SettingsList from 'pages/admin/settings/list';
import FormUser from 'pages/admin/user/form';
import UserList from 'pages/admin/user/list';
import { urls } from 'routes/app';

export const paths = [
    // Database
    {
        path: urls.admin.database.add,
        component: FormDatabase
    },
    {
        path: urls.admin.database.edit,
        component: FormDatabase
    },
    {
        path: urls.admin.database.list,
        component: DatabaseList
    },

    // Client Invoice
    {
        path: urls.admin.client.invoice.list,
        component: ClientInvoiceList
    },

    // Client
    {
        path: urls.admin.client.add,
        component: FormClient
    },
    {
        path: urls.admin.client.edit,
        component: FormClient
    },
    {
        path: urls.admin.client.database,
        component: FormClientDatabase
    },
    {
        path: urls.admin.client.list,
        component: ClientList
    },

    // Service
    {
        path: urls.admin.service.add,
        component: FormService
    },
    {
        path: urls.admin.service.edit,
        component: FormService
    },
    {
        path: urls.admin.service.list,
        component: ServiceList
    },

    // Invoice
    {
        path: urls.admin.invoice.add,
        component: FormInvoice
    },
    {
        path: urls.admin.invoice.edit,
        component: FormInvoice
    },
    {
        path: urls.admin.invoice.list,
        component: InvoiceList
    },
    {
        path: urls.admin.invoice_all.list,
        component: InvoiceListAll
    },

    // ChangePassword
    {
        path: urls.admin.change_password,
        component: ChangePassword
    },

    // Settings
    {
        path: urls.admin.settings.edit,
        component: FormSettings
    },
    {
        path: urls.admin.settings.list,
        component: SettingsList
    },

    // Users
    {
        path: urls.admin.user.edit,
        component: FormUser
    },
    {
        path: urls.admin.user.list,
        component: UserList
    },

    // Dashboard
    {
        path: urls.admin.index,
        component: AdminDashboard
    }
];
