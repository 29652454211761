import React, { useEffect } from 'react';
import User from 'services/auth/user';
import { reverse } from 'named-urls/dist/index.es';
import { urls } from 'routes/app';

export default function Logout() {
    useEffect(() => {
        User.logout().then((data) => {
            window.location.href = reverse(urls.auth.login);
        });
    });

    return <h3>Deslogando ...</h3>;
}
