import { reverse } from 'named-urls';
import { urls } from 'routes/api';
import api from 'helpers/api';

export default class Service {
    constructor(id_service = 0) {
        this.id_service = id_service;
    }

    async list(params) {
        let url = reverse(urls.admin.service.get);
        const { data } = await api.get(url, { params: params });
        return data;
    }

    async show() {
        let url = reverse(urls.admin.service.edit, { id_service: this.id_service });
        const { data } = await api.get(url);
        return data;
    }

    async update(values) {
        let url = reverse(urls.admin.service.edit, { id_service: this.id_service });
        const { data } = await api.put(url, values);
        return data;
    }

    async create(values) {
        let url = reverse(urls.admin.service.get);
        const { data } = await api.post(url, values);
        return data;
    }

    async save(values) {
        if (this.id_service > 0) {
            return this.update(values);
        }
        return this.create(values);
    }
}
