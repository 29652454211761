export const configs = {
    save_error: 'Oops... ocorreu um erro ao salvar, tente atualizar a página.',
    save_error_title: '<p>Erro...</p>',
    save_success: 'Salvo com sucesso!',
    save_error_validation: 'Ops... Corrija os campos indicados.',
    toast_appearance: 'success',
    edit_sufix: '/edit',

    components: {
        submit_row: {
            delete_message: 'Deseja deletar o item?',
            delete_error: 'Ops... Ocorreu um erro ao deletar o registro...',
            delete_success: 'Registro apagado com sucesso.',
            delete_confirm: 'Sim, tenho certeza!',
            delete_cancel: 'Cancelar',
            delete_text: 'Aviso: esta operação não poderá ser desfeita.',
            back: 'Voltar',
            delete: 'Deletar',
            save: 'Salvar',
            save_add: 'Salvar e Novo',
            save_continue: 'Salvar e Continuar'
        },
        panel: {
            default_title: 'Opções Gerais'
        },
        stacked_inline: {
            remove: 'Apagar',
            add: 'Adicionar'
        }
    },

    // Base breadcrumb for crud
    base_breadcrumb: {
        icon: 'home',
        url: '/admin',
        label: 'Início'
    }
};
